import { LogLevel, Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_ClientId as string,
        authority: process.env.REACT_APP_Instance + '/' + process.env.REACT_APP_TenantId,
        redirectUri: process.env.REACT_APP_redirectUri, 
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: string, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

export const loginApiRequest = {
    scopes: ['api://' + process.env.REACT_APP_ApiId + '/Api.Access'],
};
export const odataApiRequest = {
    scopes: ['api://' + process.env.REACT_APP_ODataId + '/Api.Access'],
};
export const azureManagement = {
    scopes: ["https://management.azure.com/.default"],
};
export const adx = {
    scopes: ["https://help.kusto.windows.net/user_impersonation"],
};
export const storage = {
    scopes: ["https://storage.azure.com/user_impersonation"],
};
export const dt = {
    scopes: ["https://digitaltwins.azure.net/.default"]
};
export const user = {
    scopes: ["user.read"]
};