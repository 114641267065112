import { useContext, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Asside, AssideItems, AssideItem, AssideItemLink, AssideItemLinkProps } from '../asside'
import { Toolbar } from '../toolbar/Toolbar'
import { ProfileMenu } from '../profilemenu/ProfileMenu';
import { ProfileMenuItem } from '../profilemenu/ProfileMenuItem';
import { SignOutButton } from '../profilemenu/SignOut';
import { NavLink } from 'react-router-dom'
import lightLogo from '../../assets/logos/logo-light.svg'
import { ApplicationContext } from '../../services/ContextService'
import { apiService, Dashboard } from '../../services/ApiService'
import { DashboardMenu, DashboardCreate } from '../dashboard'

type LayoutAuthenticatedProps = {
	children?: React.ReactNode;
};

export const LayoutAuthenticated: React.FC<LayoutAuthenticatedProps> = ({ children }) => {
	const [dashboardElements, setDashboardElements] = useState<React.ReactElement<React.FC<AssideItemLinkProps>>[]>([]);
	const { dashboards, portal } = useContext(ApplicationContext);

	const _clearDashboardFn = () => {
		setTimeout(() => {
			portal?.current?.setContent(<></>);
		}, 500);
	}
	const _createDashboardFn = () => {
		portal?.current?.setContent(<DashboardCreate onCancel={_clearDashboardFn} onSave={_clearDashboardFn}></DashboardCreate>);
	};
	const _createDashboard = <AssideItemLink key={1001} name="Create new .." icon="bi bi-plus" size={7} onClick={_createDashboardFn}></AssideItemLink>

	useEffect(() => {
		if (dashboards) {
			const _elements = DashboardMenu(dashboards);
			_elements.push(_createDashboard);

			setDashboardElements(_elements);
		}
		else
			setDashboardElements([]);
	}, [dashboards]);

    return (
		<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
			<div
				id="kt_app_header"
				className="app-header d-flex d-lg-none"
				data-kt-sticky="true"
				data-kt-sticky-activate="{default: false, lg: true}"
				data-kt-sticky-name="app-header-sticky"
				data-kt-sticky-offset="{default: false, lg: '300px'}"
			>
				<div className="app-container container-xxl d-flex align-items-center justify-content-between" id="kt_app_header_container">
					<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
						<NavLink to="/">
							<img alt="Logo" src={lightLogo} className="h-30px"></img>
						</NavLink>
					</div>
					<div className="d-flex align-items-center d-lg-none ms-2" title="Show sidebar menu">
						<div className="btn btn-icon btn-color-white bg-white bg-opacity-0 bg-hover-opacity-10 w-35px h-35px active" id="kt_app_sidebar_mobile_toggle">
							<span className="svg-icon svg-icon-2">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"></path>
									<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor"></path>
								</svg>
							</span>
						</div>
					</div>
				</div>
			</div>
				<div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
					<Asside>
					<AssideItems>
						<AssideItem icon="house" name="Home">
							<AssideItemLink key={-1} name="Welcome" href="/"></AssideItemLink>
						</AssideItem>
						<AssideItem icon="stats" name="Dashboards">
						{dashboardElements.length === 0 ?
							<div className="menu-item">
								<div className="menu-content text-center">
									<span className="spinner-border text-primary"></span>
								</div>
							</div>
							:
							dashboardElements
						}
						</AssideItem>
						<AssideItem icon="settings" name="Management">
							<AssideItemLink key={-2} name="Hierarchy" href="/hierarchy"></AssideItemLink>
						</AssideItem>
					</AssideItems>
					<ProfileMenu>
						<ProfileMenuItem>
							<SignOutButton></SignOutButton>
						</ProfileMenuItem>
					</ProfileMenu>
				</Asside>
				
				<div
					className="app-main flex-column flex-row-fluid"
					id="kt_app_main">
					<div className="d-flex flex-column flex-column-fluid">
							<Toolbar></Toolbar>
							<ApplicationContext.Consumer>
								{ctx =>  
									<div id="kt_app_content" className="app-content flex-column-fluid">
										<div id="kt_app_content_container" className={ctx.fluid ? "app-container container-fluid" : "app-container container-xxl"}>
											<Container fluid={true}>
												{children}
											</Container>
										</div>
									</div>
								}
							</ApplicationContext.Consumer>
						
					</div>
				</div>
			</div>
		</div>
    );
}
