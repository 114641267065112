import { useContext, useState, useEffect, useId, ReactElement, useRef, createContext, forwardRef, useImperativeHandle } from 'react';
import { ChartWidget } from '../widgets/chart'
import { ObjectViewerWidget } from '../widgets/objectviewer'
import { StatusWidget } from '../widgets/status'
import { apiService, Dashboard, Telemetry } from '../services/ApiService'
import { Responsive, WidthProvider, Layouts, Layout } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './styles.css'
import { useMemo } from 'react';
import { Console } from 'console';
import React from 'react';
import { createPortal } from 'react-dom';
import uuid from 'react-uuid';
import moment from 'moment';
import { ApplicationContext } from '../services/ContextService';

declare global {
	interface Window {
		KTMenu: {
			init: () => void;
			getInstance: (element: any) => any;
			hideDropdowns: () => void;
		};
	}
}

type ResizeHandle = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';

export interface IWidgetContext {
	telemetry: Telemetry[]
}
export const WidgetContext = createContext<IWidgetContext>({} as IWidgetContext);

export interface IWidget<T> {
	Configure: React.FC<WidgetConfigurationProps>
	Render: React.ForwardRefExoticComponent<React.PropsWithoutRef<T> & React.RefAttributes<WidgetRef>>;
}
export type Widget = {
	id: string;
	title: string;
	type: string;
	historical: boolean;
	position: {
		x: number;
		y: number;
		w: number;
		h: number;
		minW?: number;
		maxW?: number;
		minH?: number;
		maxH?: number;
		moved?: boolean;
		static?: boolean;
		isDraggable?: boolean;
		isResizable?: boolean;
		resizeHandles?: ResizeHandle[];
		isBounded?: boolean;
	};
	[options: string]: unknown;
	telemetry: WidgetTelemetry[]
};
export type WidgetTelemetry = {
	id: string;
	name: string;
	[options: string]: any;
};
export type WidgetRef = {
	onData: (data: Telemetry[]) => void;
};

export type WidgetContainerProps = {
	widgets: Widget[];
	owner: boolean;
	onChange?: (widgets: Widget[]) => void;
	onRefresh?: (refreshing: boolean) => void;
};
export type WidgetContainerRef = {
	setData: (start: Date, end: Date, interval: number) => void;
	refresh: (start: Date, end: Date) => void;
	addWidget: (type: string) => void;
	saveWidgets: () => void;
}
export type WidgetComponentProps = {
	widget: Widget;
	owner: boolean;
	children?: React.ReactElement | React.ReactElement[],
	functions?: WidgetFunctions;
};
export type WidgetPortalProps = {};
export type WidgetPortalRef = {
	setContent: (content: JSX.Element) => void;
};
export type WidgetFunctions = {
	onAdd?: (widget: Widget) => void;
	onEdit?: (widget: Widget) => void;
	onDelete?: (id: string) => void;
	children?: ReactElement;
	props?: WidgetComponentProps;
	[options: string]: unknown;
};
export type WidgetConfigurationProps = {
	widget: Widget,
	onSave?: (widget: Widget) => void;
	onCancel?: () => void;
}

export const WidgetContainer = forwardRef<WidgetContainerRef, WidgetContainerProps>(({ widgets, owner, onRefresh, onChange }, ref) => {
	const ctx = useContext(ApplicationContext);

	const ResponsiveGridLayout = WidthProvider(Responsive);
	const [_widgets, _setWidgets] = useState<Widget[]>(widgets ?? []);
	let _layout = [] as Layout[];

	const refData = useRef<WidgetRef[]>([]);
	const getData = async (start: Date, end: Date) => {
		onRefresh?.(true);

		let _historicalTelemetrys = [] as WidgetTelemetry[];
		_historicalTelemetrys = _historicalTelemetrys.concat.apply([], _widgets?.filter(w => w.historical === true).map(w => w.telemetry));

		let _historicalTelemetryIds = _historicalTelemetrys.map(t => t.id).filter((t, i, s) => { return s.indexOf(t) === i; });

		let _currentTelemetrys = [] as WidgetTelemetry[];
		_currentTelemetrys = _currentTelemetrys.concat.apply([], _widgets?.filter(w => w.historical === false).map(w => w.telemetry));

		let _currentTelemetryIds = _currentTelemetrys.map(t => t.id).filter((t, i, s) => { return s.indexOf(t) === i && _historicalTelemetryIds.indexOf(t) == -1; });

		let _historicalData = _historicalTelemetryIds.length > 0 ? await apiService.getTelemetryHistory(ctx.organizationId as string, _historicalTelemetryIds, start, end) : [];
		let _currentData = _currentTelemetryIds.length > 0 ? await apiService.getTelemetryCurrent(ctx.organizationId as string, _currentTelemetryIds) : [];

		let _data = _historicalData.concat(_currentData);
		refData?.current?.forEach(w => w?.onData(_data));

		onRefresh?.(false);
	}

	let _lastStart = moment().startOf('day').toDate();
	let _lastEnd = moment().endOf('day').toDate();
	const intervalTimer = useRef<NodeJS.Timer | null>(null);
	useImperativeHandle(ref, () => ({
		setData(start: Date, end: Date, interval: number) {
			_lastStart = start;
			_lastEnd = end;

			if (intervalTimer.current) {
				clearInterval(intervalTimer.current);
				intervalTimer.current = null;
			}

			getData(start, end);
			intervalTimer.current = setInterval(() => {
				getData(start, end);
			}, interval)
		},
		refresh(start: Date, end: Date)
		{
			_lastStart = start;
			_lastEnd = end;

			getData(start, end);
			//if (intervalTimer?.current)
			//	intervalTimer.current.ref();
		},
		addWidget(type: string) {
			const key = uuid();
			const componentOf: { [name: string]: React.FC<WidgetConfigurationProps> } = {
				'chart': ChartWidget.Configure,
				'objectviewer': ObjectViewerWidget.Configure,
				'status': StatusWidget.Configure
			};
			ctx.portal?.current?.setContent(React.createElement(componentOf[type], {
				key: key,
				widget: {
					id: uuid()
				} as Widget,
				onSave: (widget: Widget) => {
					const _items = _widgets
						.map(w => { w.position = _layout.find(c => c.i == w.id) ?? w.position; return w; });
					_items.push(widget)
					_layout.push({ ...widget.position, i: widget.id })

					onChange?.(_items);
					_setWidgets(_items);

					setTimeout(() => {
						ctx.portal?.current?.setContent(<></>);
					}, 500);
				},
				onCancel: () => {
					setTimeout(() => {
						ctx.portal?.current?.setContent(<></>);
					}, 500);
				}
			}));
		},
		saveWidgets() {
			const _items = _widgets
				.map(w => { w.position = _layout.find(c => c.i === w.id) ?? w.position; return w; });

			onChange?.(_items);
		}
	}));

	useEffect(() => {
		_layout = widgets?.map(item => { return { i: item.id, ...item.position } }) ?? []
	}, [widgets]);
	useEffect(() => {
		getData(_lastStart, _lastEnd);
	}, [_widgets]);
	useEffect(() => () => {
		if (intervalTimer.current) {
			clearInterval(intervalTimer.current);
			intervalTimer.current = null;
		}
	}, []);

	const functions = {
		onLayout: (current: Layout[]) => {
			_layout = current;

			const _items = _widgets
				.map(w => { w.position = current.find(c => c.i == w.id) ?? w.position; return w; });

			onChange?.(_items)
		},
		onItemCallback: (
			layout: Layout[],
			oldItem: Layout,
			newItem: Layout,
			placeholder: Layout,
			e: MouseEvent,
			element: HTMLElement) => functions.onLayout(layout),
		onEdit: (widget: Widget) => {
			const key = uuid();
			const componentOf: { [name: string]: React.FC<WidgetConfigurationProps> } = {
				'chart': ChartWidget.Configure,
				'objectviewer': ObjectViewerWidget.Configure,
				'status': StatusWidget.Configure
			};
			ctx.portal?.current?.setContent(React.createElement(componentOf[widget.type], {
				key: key,
				widget: widget,
				onSave: (widget: Widget) => {
					const _items = _widgets
						.map(w => w.id === widget.id ? widget : w)
						.map(w => { w.position = _layout.find(c => c.i === w.id) ?? w.position; return w; });

					onChange?.(_items);
					_setWidgets(_items);

					setTimeout(() => {
						ctx.portal?.current?.setContent(<></>);
					}, 500);
				},
				onCancel: () => {
					setTimeout(() => {
						ctx.portal?.current?.setContent(<></>);
					}, 500);
				}
			}));
		},
		onDelete: (id: string) => {
			const _items = _widgets
				.filter(w => w.id !== id)
				.map(w => { w.position = _layout.find(c => c.i == w.id) ?? w.position; return w; });

			onChange?.(_items);
			_setWidgets(_items);
		}
	}

	return (
		<>
			<ResponsiveGridLayout
				useCSSTransforms={false}
				className="layout"
				onDragStop={functions.onItemCallback}
				onResizeStop={functions.onItemCallback}
				breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
				cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 4 }}
				rowHeight={30}
				draggableHandle='.card-header > h3'
				isDraggable={owner}
				isResizable={owner}
			>
				{_widgets?.map((i, index) => {
					const { id, title, subTitle, type, position, ...rest } = i;
					const componentOf: { [name: string]: React.ForwardRefExoticComponent<React.PropsWithoutRef<any> & React.RefAttributes<WidgetRef>> } = {
						'chart': ChartWidget.Render,
						'objectviewer': ObjectViewerWidget.Render,
						'status': StatusWidget.Render,
					};
					const Component = componentOf[type];
					const content = <Component ref={w => refData.current[index] = w as WidgetRef} {...rest}></Component>
					return (<div key={id} data-grid={{ x: position.x, y: position.y, w: position.w, h: position.h, minW: position.minW, maxW: position.maxW, minH: position.minH, maxH: position.maxH }} ><WidgetComponent widget={i} owner={owner} functions={functions}>{content}</WidgetComponent></div>)
				})}
			</ResponsiveGridLayout>
			</>
    );
})
const WidgetComponent: React.FC<WidgetComponentProps> = ({ widget, owner, functions, children }) => {
	const uuid = useId();

	useEffect(() => {
		if (owner) {
			window.KTMenu.init();

			var menuEl = document.querySelector('#' + CSS.escape(uuid));
			var menu = window.KTMenu.getInstance(menuEl);
			menu.on("kt.menu.link.click", function (link: Element) {
				if (link.hasAttribute('data-widget-delete')) {
					functions?.onDelete?.(widget.id);
				}
				if (link.hasAttribute('data-widget-edit')) {
					functions?.onEdit?.(widget);
				}
			});
		}
	}, []);

	return (
		<div key={widget.id} id={widget.id} className="card card-flush h-lg-100 overflow-hidden">
			<div className="card-header d-flex">
				<h3 className={owner ? "card-title align-items-start flex-column flex-grow-1 move" : "card-title align-items-start flex-column flex-grow-1"}>
					<span className="card-label fw-bold text-light">{widget.title}</span>
				</h3>
				{owner ?
					<div className="card-toolbar">
						<button className="btn btn-icon btn-color-gray-400 btn-active-color-light justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
							<span className="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"></rect>
									<rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
									<rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
									<rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"></rect>
								</svg>
							</span>
						</button>
						<div id={uuid} className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
							<div className="menu-item px-3">
								<div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
							</div>
							<div className="separator opacity-75"></div>
							<div className="menu-item px-3 mb-3">
								<a className="menu-link px-3 bg-hover-light" data-widget-edit>Edit</a>
							</div>
							<div className="menu-item px-3 mb-3">
								<a className="menu-link px-3 text-danger bg-hover-light-danger" data-widget-delete>Delete</a>
							</div>
						</div>
					</div>
					:
					<></>
				}
				</div>
				<div className="card-body d-flex flex-column pt-0 pb-1 px-0">
					{children}
				</div>

			</div>
	)
}
