import { useState, useContext } from 'react';
import { authService } from '../../services/AuthService';
import { apiService, Organization } from '../../services/ApiService';
import { ProfilePhoto } from './ProfilePhoto'
import { ProfileSubmenu } from './ProfileSubmenu'
import { ProfileMenuSeperator } from './ProfileMenuSeperator'
import { ProfileMenuItem } from './ProfileMenuItem';
import { ApplicationContext } from '../../services/ContextService'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export type ProfileMenuProps = {
    children?: React.ReactNode;
};

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ children }) => {
	const ctx = useContext(ApplicationContext);
	const navigate = useNavigate();

	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const setOrganization = (id: string) => {
		ctx.loading?.(true);

		Promise.resolve(apiService.setOrganization(id).then(() => {
			Promise.resolve(apiService.getOrganizations()).then((res) => {
				setOrganizations(res);

				const _active = res.find(o => o.isActive)
				if (_active)
					ctx.organizationId = _active.id;

				ctx.setOrganizationId?.(id);

				navigate("/");
				ctx.loading?.(false);
			})
		}));
	};

	useEffect(() => {
		Promise.resolve(apiService.getOrganizations()).then((res) => {
			setOrganizations(res);

			const _active = res.find(o => o.isActive)
			if (_active)
				ctx.setOrganizationId?.(_active.id);
			else if(res.length > 0) {
				Promise.resolve(apiService.setOrganization(res[0].id));
				ctx.setOrganizationId?.(res[0].id);
			}
		});
	}, [])

	return (
		<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
			<>
				<div className="menu-item px-3">
					<div className="menu-content d-flex align-items-center px-3">
						<div className="symbol symbol-50px me-5">
							<ProfilePhoto className="w-50px h-50px"></ProfilePhoto>
						</div>
						<div className="d-flex flex-column">
							<div className="fw-bold d-flex align-items-center fs-5">{authService.user()?.name}</div>
							<a href="#" className="fw-semibold text-muted text-hover-primary fs-7">{authService.user()?.username}</a>
						</div>
					</div>
				</div>
				{organizations.length  > 1 ?
					<ProfileSubmenu title="Organizations">
						{organizations.map(o =>
							<ProfileMenuItem>
								<a className={o.isActive ? "menu-link px-5 text-nowrap active" : "menu-link px-5 text-nowrap"} onClick={() => setOrganization(o.id) } >{o.name}</a>
							</ProfileMenuItem>
						)}
					</ProfileSubmenu> :
					<></>
				}
				<ProfileMenuSeperator></ProfileMenuSeperator>
				{children}
			</>
		</div>
);
}
