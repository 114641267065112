import { Component, ReactElement } from 'react';
import { IApplicationContext, ApplicationContext } from '../../services/ContextService'

type PageLayoutProps = {
    name: string;
    path: { name: string, href?: string }[];
    items?: ReactElement;
    children?: React.ReactElement | React.ReactElement[];
};

export abstract class PageLayout extends Component<PageLayoutProps> {
    static contextType = ApplicationContext;

    constructor(props: PageLayoutProps, context: IApplicationContext) {
        super(props, context);
        context.toolbar?.setName(this.props.name)
        context.toolbar?.setPath(this.props.path)
        context.toolbar?.setItems(this.props.items ?? <></>)
    }

    componentDidUpdate() {
        const context = this.context as IApplicationContext;
        context.toolbar?.setName(this.props.name)
        context.toolbar?.setPath(this.props.path)
        context.toolbar?.setItems(this.props.items ?? <></>)
    }

    render() {
        return (<>{this.props.children}</>);
    }
}