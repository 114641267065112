import { Children, Component } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    children?: React.ReactNode;
    href?: string;
};

export class ProfileMenuItem extends Component<Props> {
    static displayName = ProfileMenuItem.name;

  constructor (props: Props) {
    super(props);
  }

    render() {
        if (this.props.href)
            return (
                <div className="menu-item px-5">
                    <Link className="menu-link px-5" to={this.props.href as string}>{this.props.children}</Link>
                </div>
            );
        else
            return (
                <div className="menu-item px-5">
                    {this.props.children}
                </div>
            );
  }
}
