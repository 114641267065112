import ODataClient from '../modules/axios-odata'
import axios from "axios";
import { Widget } from '../widgets/';
import { authService } from './AuthService';

const _dashboardInstance = axios.create();
_dashboardInstance.interceptors.request.use(
    async (request) => {
        const token = await authService.getToken('pulse');
        const headers = { ...request.headers, Authorization: 'Bearer ' + token } as any;

        request.headers = headers
        return request;
    },
    (err) => {
        return Promise.reject(err);
    }
);

const _odataConfig = {
    baseURL: process.env.REACT_APP_ODataURI,
    timeout: 30000,
};
const _odataInstance = axios.create(_odataConfig);
_odataInstance.interceptors.request.use(
    async (request) => {
        const token = await authService.getToken('odata');
        const headers = {
            ...request.headers,
            Authorization: 'Bearer ' + token
        } as any;

        request.headers = headers
        return request;
    },
    (err) => {
        return Promise.reject(err);
    }
);
const _oDataClient = new ODataClient(_odataInstance);

export type Space = {
    id: string;
    name: string;
    meters?: Meter[];
    icon?: string;
    color?: string;
}
export type Meter = {
    id: string;
    spaceId: string;
    name: string;
    capabilities?: Capability[];
}
export type Capability = {
    id: string;
    spaceId: string;
    meterId: string;
    name: string;
}

export type Organization = {
    id: string;
    name: string;
    isActive: boolean;
};
export type Dashboard = {
    id: string;
    name: string;
    shared: boolean;
    owner: boolean;
    widgets: Widget[];
};
export type Telemetry = {
    id: string;
    data: { date: Date, value: string | number | boolean }[]
    unit?: string
};
export type FileInfo = {
    id: string;
    name: string;
    shared: boolean;
};


export default class ApiService {
    getOrganizations = async (): Promise<Organization[]> => {
        const response = await _dashboardInstance.get<Organization[]>('/api/organizations');
        const data = await response.data;

        return data;
    };
    setOrganization = async (organizationId: string): Promise<boolean> => {
        const response = await _dashboardInstance.patch('/api/organizations/' + organizationId);
        return response.status == 200;
    }

    getDashboards = async (organizationId: string): Promise<Dashboard[]> => {
        const response = await _dashboardInstance.get<Dashboard[]>('/api/organizations/' + organizationId +'/dashboards');
        const data = await response.data;

        return data;
    };
    getDashboard = async (organizationId: string, dashboardId: string) => {
        const response = await _dashboardInstance.get<Dashboard>('/api/organizations/' + organizationId +'/dashboards/' + dashboardId);
        const data = await response.data;

        return data;
    };
    addDashboard = async (organizationId: string, name: string, shared: boolean): Promise<Dashboard> => {
        const _dashboard = {
            name: name,
            shared: shared,
            widgets: []
        };
        const dashboard = await _dashboardInstance.post<Dashboard>('/api/organizations/' + organizationId +'/dashboards', _dashboard);
        const data = await dashboard.data;

        return data;
    };
    editDashboard = async (organizationId: string, dashboardId: string, dashboard: Dashboard): Promise<boolean> => {
        dashboard.id = dashboardId;
        const data = await _dashboardInstance.put<Dashboard>('/api/organizations/' + organizationId + '/dashboards/' + dashboardId, dashboard);
        return data.status == 200;
    };

    getHierachy = async (organizationId: string, parentId?: string | undefined): Promise<Space[]> => {
        let _uri = '/api/organizations/' + organizationId + '/hierachy';
        if (parentId)
            _uri += '/' + parentId

        const response = await _dashboardInstance.get<Space[]>(_uri);
        const data = await response.data;

        return data;
    };

    getSpaces = async (organizationId: string): Promise<Space[]> => {
        let _result = await _oDataClient.query()
            .entities(organizationId + '/spaces')
            .execute();

        if (_result.status !== 200)
            return [];

        return _result.data
            .map((r: any) => { return { id: r.id, name: r.name ?? r.id } as Space })
            .sort((a: Space, b: Space) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    };
    getSpaceIdFor = async (organizationId: string, meterId: string) => {
        let _result = await _oDataClient.query()
            .entities(organizationId + '/meters?key=' + meterId)
            .execute();

        if (_result.status !== 200)
            return [];

        return _result.data[0].spaceIds[0];
    };
    getMeters = async (organizationId: string, spaceId?: string): Promise<Meter[] > => {
        let _result = spaceId ?
            await _oDataClient.query()
                .entities(organizationId + '/spaces(' + spaceId  + ')/meters')
                .execute() :
            await _oDataClient.query()
                .entities(organizationId + '/meters')
                .execute();

        if (_result.status !== 200)
            return [];

        return _result.data
            .map((r: any) => { return { id: r.id, spaceId: r.spaceIds?.[0], name: r.name ?? r.id } as Meter })
            .sort((a: Meter, b: Meter) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    };
    getMeterIdFor = async (organizationId: string, capabilityId: string) => {
        let _result = await _oDataClient.query()
            .entities(organizationId + '/capabilities?key=' + capabilityId)
            .execute();

        if (_result.status !== 200)
            return [];

        return _result.data[0].meterIds[0];
    };
    getCapabilities = async (organizationId: string, meterId?: string): Promise<Capability[]> => {
        let _result = meterId ?
            await _oDataClient.query()
                .entities(organizationId + '/meters(' + meterId + ')/capabilities')
                .execute() :
            await _oDataClient.query()
                .entities(organizationId + '/capabilities')
                .execute();

        if (_result.status !== 200)
            return [];

        return _result.data
            .map((r: any) => { return { id: r.id, meterId: r.meterIds?.[0], name: r.name ?? r.id } as Capability })
            .sort((a: Capability, b: Capability) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    };

    getTelemetryOptions = async (organizationId: string): Promise<Space[]> => {
        var _spaces = await this.getSpaces(organizationId);
        var _meters = await this.getMeters(organizationId);
        var _capabilities = await this.getCapabilities(organizationId);

        var _result = _spaces.map(s => {
            return {
                id: s.id,
                name: s.name,
                meters: _meters.filter(m => m.spaceId == s.id).map(m => {
                    return {
                        id: m.id,
                        spaceId: m.spaceId,
                        name: m.name,
                        capabilities: _capabilities.filter(c => c.meterId == m.id)
                    } as Meter
                })
            } as Space
        });

        return _result;
    }
    getTelemetryHistory = async (organizationId: string, telemetryIds: string[], start: Date, end: Date): Promise<Telemetry[]> => {
        let _result = await _oDataClient.query()
            .entities(organizationId + '/telemetry?key=' + telemetryIds.join('&key=') + '&start=' + JSON.stringify(start).replace(/"|'/g, '') + '&end=' + JSON.stringify(end).replace(/"|'/g, ''))
            .execute();

        if (_result.status !== 200)
            return [];

        return _result.data;
    };
    getTelemetryCurrent = async (organizationId: string, telemetryIds: string[]): Promise<Telemetry[]> => {
        let _result = await _oDataClient.query()
            .entities(organizationId + '/telemetry?key=' + telemetryIds.join('&key='))
            .execute();

        if (_result.status !== 200)
            return [];

        return _result.data;
    };

    getFiles = async (organizationId: string): Promise<FileInfo[]> => {
        const response = await _dashboardInstance.get<Dashboard[]>('/api/organizations/' + organizationId +'/files');
        const data = await response.data;

        return data;
    };
    uploadFile = async (organizationId: string, file: File): Promise<FileInfo> => {
        const _form = new FormData();
        _form.append("file", file);

        const response = await _dashboardInstance.post<FileInfo>('/api/organizations/' + organizationId +'/files', _form);
        return response.data;
    };
}

export const apiService: ApiService = new ApiService();