import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "../../authConfig";
import microsoftLogo from '../../assets/images/logos/microsoft.svg'

export const SignInButton = () => {
    const { instance } = useMsal();
    const handleLogin = () => {
        instance.loginRedirect(loginApiRequest).catch((e) => {
            console.log(e);
        });
    };
    return <a onClick={handleLogin} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
        <img alt="Logo" src={microsoftLogo} className="h-15px me-3"></img>Sign in with Microsoft
        </a>;
};