import { authService } from "../../services/AuthService"
import accountBlank from '../../assets/images/account/blank.png'
import { useAsync, PromiseFn } from "react-async"

const _photoFn: PromiseFn<Blob> = async () => {
    const token = await Promise.resolve(authService.getToken("user")); 
    const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: { 'Authorization': 'Bearer ' + token },
    });
    if (!response.ok)
        throw new Error('Failed to fetch profile picture.');
    else
        return response.blob();
}
const _fetchPhoto = () => {
    const _photo = useAsync({ promiseFn: _photoFn });
    return _photo.data as Blob;
}

export const ProfilePhoto = (props: { className?: string }) => {
    if (!authService.isAuthenticated()) {
        return <img className="rounded w-40px h-40px" src={accountBlank} alt="user"></img>;
    }
    else
    {
        try {
            const _photo = _fetchPhoto();
            return <img
                className={props.className}
                src={URL.createObjectURL(_photo)}
                alt="user"
            ></img>;
        }
        catch {
            return <img className="rounded w-40px h-40px" src={accountBlank} alt="user"></img>;
        }
    }
};