import { useState, useEffect, useId, useMemo, useImperativeHandle, forwardRef, useContext } from "react";
import uuid from 'react-uuid';
import { IWidget, Widget, WidgetTelemetry } from '../';
import { Telemetry } from '../../services/ApiService'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MultiTelemetryPicker } from '../../components/forms/telemetrypicker'
import { ApplicationContext } from '../../services/ContextService';

export type StatusWidgetProps = {
	round?: number;
	telemetry: WidgetTelemetry[];
	[settings: string]: unknown;
};

export const StatusWidget: IWidget<StatusWidgetProps> = {
	Configure: ({ widget, onSave, onCancel }) => {
		const ctx = useContext(ApplicationContext);
		const [show, setShow] = useState(false);
		useEffect(() => setShow(true), []);

		const { id, title, type, position, telemetry, round, ...rest } = widget as Widget;

		const [_title, _setTitle] = useState<string>(title ?? '');
		const [_telemetry, _setTelemetry] = useState<WidgetTelemetry[]>(telemetry ?? []);
		const [_round, _setRound] = useState<number>(round as number ?? 2);
		const [_settings, _setSettings] = useState<any>(rest.settings ?? {});


		const handleClose = () => {
			onCancel?.();
			setShow(false);
		}
		const handleSave = () => {
			const _widget: Widget = {
				id: id,
				title: _title,
				type: 'status',
				position: position ?? {
					w: 6,
					h: 6,
					x: 0,
					y: 0,
					minW: 4,
					minH: 4
				},
				round: _round,
				telemetry: _telemetry,
				historical: true,
				settings: _settings
			}

			onSave?.(_widget);
			setShow(false);
		}

		return (
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Status</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-7">
							<Form.Label className="required fs-6 fw-semibold form-label mb-2">Title</Form.Label>
							<Form.Control
								className="form-control-solid"
								size="sm"
								type="text"
								placeholder="Enter title .."
								value={_title}
								onChange={(e) => { _setTitle(e.target.value) }}
								autoFocus
							/>
						</Form.Group>
						<div className="mb-7">
							<label className="required fs-6 fw-semibold form-label mb-2">Round to decimals</label>
							<input className="form-control form-control-sm" type="number" min={0} value={_round} onChange={((event) => _setRound(Number(event.target.value)))} ></input>
						</div>
						<div className="mb-7">
							<label className="required fs-6 fw-semibold form-label mb-2">Telemetry</label>
							<MultiTelemetryPicker organizationId={ctx.organizationId as string} values={_telemetry.map(t => { return { organizationId: ctx.organizationId as string, capabilityId: t.id, name: t.name, color: t.color } })} onChange={(values) => _setTelemetry(values.map(v => { return { id: v.capabilityId as string, name: v.name as string } }))}></MultiTelemetryPicker>
						</div>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" size="sm" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" size="sm" onClick={handleSave}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		)
	},
	Render: forwardRef((props, ref) => {
		const { round, telemetry, ...settings } = props as StatusWidgetProps;
		const [values, setValues] = useState<Telemetry[]>([]);

		useImperativeHandle(ref, () => ({
			onData(data: Telemetry[]) {
				setValues(data);
			}
		}));

		function value(value: string | boolean | number | undefined): string {
			if (value === undefined)
				return '';

			if (
				(value != null) &&
				(value !== '') &&
				!isNaN(Number(value.toString()))
			)
				return (value as number).toFixed(round as number);

			return value.toLocaleString();
		}

		return (
			<div className="px-4 py-6">
				{telemetry.map((t, index) => {
					const _unit = values.find(v => v.id === t.id)?.unit;
					const _current = values.find(v => v.id === t.id)?.data?.pop();
					const _previous = values.find(v => v.id === t.id)?.data?.pop();
					const _icon = (_current?.value ?? 0) > (_previous?.value ?? 0) ? 
						<span className="svg-icon svg-icon-2 svg-icon-success me-2">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect opacity="0.5" x="16.9497" y="8.46448" width="13" height="2" rx="1" transform="rotate(135 16.9497 8.46448)" fill="currentColor"></rect>
								<path d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z" fill="currentColor"></path>
							</svg>
						</span> :
						(_current?.value ?? 0) < (_previous?.value ?? 0) ?
						<span className="svg-icon svg-icon-2 svg-icon-danger me-2">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect opacity="0.5" x="7.05026" y="15.5355" width="13" height="2" rx="1" transform="rotate(-45 7.05026 15.5355)" fill="currentColor"></rect>
								<path d="M9.17158 14.0284L9.17158 8.11091C9.17158 7.52513 8.6967 7.05025 8.11092 7.05025C7.52513 7.05025 7.05026 7.52512 7.05026 8.11091L7.05026 15.9497C7.05026 16.502 7.49797 16.9497 8.05026 16.9497L15.8891 16.9497C16.4749 16.9497 16.9498 16.4749 16.9498 15.8891C16.9498 15.3033 16.4749 14.8284 15.8891 14.8284L9.97158 14.8284C9.52975 14.8284 9.17158 14.4703 9.17158 14.0284Z" fill="currentColor"></path>
							</svg>
						</span> :
						<></>
					return (<>
						<div key={t.id} className="d-flex flex-stack">
							<div className="text-gray-700 fw-semibold fs-6 me-2">{t.name}</div>
							<div className="d-flex align-items-center">
								{_icon}
								<span className="text-gray-900 fw-bolder fs-2">{value(_current?.value ?? '--')}</span>
								{_unit ? <span className="text-gray-400 fw-bold fs-2 ms-1">{_unit}</span> : <></>}
							</div>
						</div>
						{index === (telemetry.length - 1) ? <></> : <div key={'sep' + t.id} className="separator separator-dashed my-3"></div>}
					</>);
				})}
			</div>
		)
	})
}