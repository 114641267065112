import { PageLayout } from '../components/layouts/PageLayout';

export function Home() {
    return (
        <PageLayout name="SmartBuilding" path={[{ name: "Dashboards" }]}>
            <p>Een smart building, ook wel een slim gebouw genoemd, is een gebouw waarbij technologie wordt gebruikt om de gebruiks- en beheerefficiëntie te verhogen. Het idee achter smart buildings is om technologie in te zetten om het gebruik van het gebouw te optimaliseren, energie- en grondstoffen te besparen en een comfortabeler en productievere omgeving te creëren voor de gebruikers.</p>
            <p>Smart buildings maken gebruik van geavanceerde technologieën, zoals bewakings- en beveiligingssystemen, verlichting, verwarming, ventilatie en airconditioning, automatische branddetectie- en blusapparatuur, energiebeheer- en -besparingstechnologieën en meer. Deze technologieën werken samen om de gebruiks- en beheerefficiëntie van het gebouw te verbeteren, zonder afbreuk te doen aan de comfort- en veiligheidseisen van de gebruikers.</p>
            <p>Smart buildings zijn ook verbonden met andere systemen en apparaten, zoals slimme huizen en slimme steden, om een efficiëntere en duurzamere gebruik van energie en grondstoffen te realiseren. Dit maakt het mogelijk om real-time gegevens te verzamelen en te analyseren om de energie- en grondstoffenefficiëntie van het gebouw te verbeteren.</p>
            <p>Smart buildings zijn zeer gunstig voor bedrijven en instellingen, omdat ze een aanzienlijke besparing op energie- en beheerkosten kunnen opleveren. Ze bieden ook een comfortabele en productieve werkomgeving voor werknemers en bezoekers.</p>
            <p>In conclusie, smart buildings zijn een belangrijke stap in de richting van een duurzamere toekomst, door de energie- en grondstoffenefficiëntie van gebouwen te verbeteren en een comfortabele en productieve werkomgeving te creëren voor de gebruikers. Bedrijven en instellingen die overstappen op smart buildings kunnen verwachten aanzienlijke besparingen op energie- en beheerkosten, evenals verbeteringen in productiviteit en comfort.</p>
            <h1>10 Redenen</h1>
            <ul>
                <li>Energiebesparing: Smart buildings maken gebruik van geavanceerde technologieën voor energiebeheer en -besparing, waardoor het energieverbruik aanzienlijk wordt verminderd.</li>
                <li>Verhoogde productiviteit: Door de optimale gebruiks- en beheerefficiëntie van het gebouw, wordt de productiviteit van de gebruikers verhoogd.</li>
                <li>Verbetering van comfort: Smart buildings bieden een comfortabele omgeving voor de gebruikers, met aangepaste verlichting, temperatuur en ventilatie.</li>
                <li>Veiligheid verbetering: Smart buildings maken gebruik van geavanceerde beveiligings- en bewakingssystemen om de veiligheid te verbeteren.</li>
                <li>Duurzaamheid: Smart buildings dragen bij aan een duurzamere toekomst door het energieverbruik en grondstoffengebruik te verminderen.</li>
                <li>Eenvoudiger beheer: Het beheer van het gebouw wordt eenvoudiger en efficiënter, waardoor tijd en kosten kunnen worden bespaard.</li>
                <li>Flexibiliteit: Smart buildings bieden de mogelijkheid om snel aan te passen aan veranderende behoeften van de gebruikers, door het gebruik van geavanceerde technologieën.</li>
                <li>Real-time monitoring: Smart buildings bieden real-time monitoring van energieverbruik, beveiliging en andere belangrijke aspecten van het gebouw.</li>
                <li>Integratie met andere systemen: Smart buildings kunnen worden geïntegreerd met andere systemen en apparaten, zoals slimme huizen en slimme steden, voor een efficiëntere en duurzamere gebruik van energie en grondstoffen.</li>
                <li>Aanzienlijke besparingen: Smart buildings kunnen aanzienlijke besparingen op energie- en beheerkosten opleveren, waardoor ze een gunstige investering zijn voor bedrijven en instellingen.</li>
            </ul>
        </PageLayout>
        )
}