import { createContext, useContext, useState, useEffect, useMemo, Children } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import lightLogo from '../../assets/logos/logo-light.svg'
import { ProfileMenuProps } from '../profilemenu/ProfileMenu';
import { ProfilePhoto } from '../profilemenu/ProfilePhoto';
import { ApplicationContext } from '../../services/ContextService';

declare global {
    interface Window {
        KTComponents: { init: () => void };
    }
}

interface IAssideContext {
    update(name: string, items: React.ReactElement<React.FC<AssideItemLinkProps>>[]): void;
}
const AssideContext = createContext<IAssideContext>({} as IAssideContext);

export type AssideProps = {
    children: [React.ReactElement<React.FC<AssideProps>>, React.ReactElement<React.FC<ProfileMenuProps>>];
};
export type AssideItemsProps = {
    children?: React.ReactElement<React.FC<AssideItemProps>> | React.ReactElement<React.FC<AssideItemProps>>[];
};
export type AssideItemProps = {
    icon: string,
    name: string,
    children?: React.ReactElement<React.FC<AssideItemLinkProps>> | React.ReactElement<React.FC<AssideItemLinkProps>>[]
};
export type AssideItemLinkProps = {
    name: string,
    href?: string,
    onClick?: () => void;
    children?: React.ReactElement<React.FC<AssideItemLinkProps>> | React.ReactElement<React.FC<AssideItemLinkProps>>[]
    icon?: string
    size?: 1|2|3|4|5|6|7|8|9|10
}

export const Asside: React.FC<AssideProps> = ({ children }) => {
    const [active, setactive] = useState<{ activeName: string | null, activeItems: React.ReactElement<React.FC<AssideItemLinkProps>>[]|null }>({ activeName: null, activeItems: null});
    const assideContext: IAssideContext = {
        update(name: string, items: React.ReactElement<React.FC<AssideItemLinkProps>>[]) { setactive({ activeName: name, activeItems: items }) }
    };

    useEffect(() => {
        window.KTComponents.init();
    }, []);
    
    return (

        <AssideContext.Provider value={assideContext}>
            <div
                id="kt_app_sidebar"
                className="app-sidebar"
                data-kt-drawer="true"
                data-kt-drawer-name="app-sidebar"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="auto"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
            >
                <div className="app-sidebar-primary">
                    <div className="app-sidebar-logo d-none d-lg-flex flex-center pt-8 pb-5" id="kt_app_sidebar_logo">
                        <NavLink to="/">
                            <img alt="Logo" src={lightLogo} className="mh-35px"></img>
                        </NavLink>
                    </div>
                    <div
                        className="app-sidebar-menu flex-grow-1 hover-scroll-overlay-y my-7 my-lg-5"
                        id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                        data-kt-scroll-offset="5px" >
                        {children[0]}
                    </div>

                    <div className="d-flex flex-column flex-center pb-8" id="kt_app_sidebar_footer">
                        <div className="mb-0" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-attach="parent" data-kt-menu-placement="right-end">
                            <ProfilePhoto className="rounded w-40px h-40px"></ProfilePhoto>
                        </div>
                        {children[1]}
                    </div>
                </div>

                <div className="app-sidebar-secondary">
                    <div
                        className="menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu"
                        data-kt-menu="true" >
                        <div
                            id="kt_app_sidebar_secondary_menu_wrapper"
                            className="hover-scroll-y px-1 px-lg-5"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="{default: false, lg: true}"
                            data-kt-scroll-height="auto"
                            data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu"
                            data-kt-scroll-offset="20px" >
                            
                            <div className="menu-item">
                                <div className="menu-content">
                                    <span className="menu-section fs-5 fw-bolder ps-1 py-1">{active.activeName}</span>
                                </div>
                            </div>
                            {active.activeItems}
                           
                        </div>
                    </div>
                </div>
                <button
                    id="kt_app_sidebar_secondary_toggle"
                    className="app-sidebar-secondary-toggle btn btn-sm btn-icon bg-body btn-color-gray-600 btn-active-color-primary position-absolute translate-middle z-index-1 start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-4"
                    data-kt-toggle="true"
                    data-kt-toggle-state="active"
                    data-kt-toggle-target="body"
                    data-kt-toggle-name="app-sidebar-secondary-collapse">
                    <span className="svg-icon svg-icon-2 rotate-180">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </button>
            </div>
        </ AssideContext.Provider>
    );
}
export const AssideItems: React.FC<AssideItemsProps> = ({ children }) => {
    return (
        <div
            id="kt_aside_menu"
            className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6"
            data-kt-menu="true"
        >
            {children}
        </div>
    );
}
export const AssideItem: React.FC<AssideItemProps> = ({ icon, name, children }) => {
    const ctx = useContext(AssideContext);

    const [activeClass, setactiveClass] = useState<string>("menu-item py-2");
    const location = useLocation()

    const _resolveHrefs = (items: React.ReactElement<React.FC<AssideItemLinkProps>>[]): string[] => {
        let _items = items.map(c => c.props as AssideItemLinkProps).filter(c => c.href).map(c => c.href);
        let _children = items.map(c => c.props as AssideItemLinkProps).filter(c => c.children);
        _children.forEach(c => {
            if (Array.isArray(c.children))
                _items.push(..._resolveHrefs(c.children as React.ReactElement<React.FC<AssideItemLinkProps>>[]));
            else
                _items.push(..._resolveHrefs([c.children as React.ReactElement<React.FC<AssideItemLinkProps>>]));
        });
        return _items as string[];
    }

    const _children = Children.toArray(children) as React.ReactElement<React.FC<AssideItemLinkProps>>[];
    const _hrefs = _resolveHrefs(_children);

    const update = () => {
        if (_hrefs.indexOf(window.location.pathname) > -1) {
            setactiveClass("menu-item py-2 here");
            ctx.update(name, _children);
        }
        else
            setactiveClass("menu-item py-2 me-0");
    }
    useEffect(update, [location, children]);

    return (
        <div
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-placement="right-start"
            className={activeClass}>
            <span className="menu-link menu-center">
                <span className="menu-icon me-0">
                    <i className={"fonticon-" + icon + " fs-1"}></i>
                </span>
            </span>
            <div
                className="menu-sub menu-sub-dropdown px-2 py-4 w-250px mh-75 overflow-auto">
                <div className="menu-item">
                    <div className="menu-content">
                        <span className="menu-section fs-5 fw-bolder ps-1 py-1">{name}</span>
                    </div>
                </div>
                {_children.slice(0, 4)}
                <div className="menu-inner flex-column collapse" id="kt_app_sidebar_menu_dashboards_collapse">
                    {_children.slice(4)}
                </div>
                {_children.length > 4 &&
                    <div className="menu-item">
                        <div className="menu-content">
                            <a className="btn btn-flex btn-color-gray-500 btn-icon-gray-400 d-flex flex-stack fs-base p-0 ms-2 mb-2 toggle collapsible collapsed" data-bs-toggle="collapse" href="#kt_app_sidebar_menu_dashboards_collapse" data-kt-toggle-text="Show Less">
                                <span data-kt-toggle-text-target="true">Show {_children.length - 4} More</span>
                                <span className="svg-icon toggle-on svg-icon-2 me-0">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                        <rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                                <span className="svg-icon toggle-off svg-icon-2 me-0">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
export const AssideItemLink: React.FC<AssideItemLinkProps> = ({ name, href, onClick, children, icon, size }) => {
    const [accordionClass, setaccordionClass] = useState<string>("menu-item menu-accordion hover");
    const [subSccordionClass, setsubAccordionClass] = useState<string>("menu-sub menu-sub-accordion");
    const location = useLocation()

    const _resolveHrefs = (items: React.ReactElement<React.FC<AssideItemLinkProps>>[]): string[] => {
        let _items = items.map(c => c.props as AssideItemLinkProps).filter(c => c.href !== undefined).map(c => c.href);
        let _parents = items.map(c => c.props as AssideItemLinkProps).filter(c => c.href === undefined);
        _parents.forEach(p => {
            _items.push(..._resolveHrefs(p.children as React.ReactElement<React.FC<AssideItemLinkProps>>[]));
        });
        return _items as string[];
    }

    const _children = Children.toArray(children) as React.ReactElement<React.FC<AssideItemLinkProps>>[];
    const _hrefs = _resolveHrefs(_children);

    const update = () => {
        if (_hrefs.indexOf(window.location.pathname) > -1) {
            setaccordionClass("menu-item menu-accordion show");
            setsubAccordionClass("menu-sub menu-sub-accordion show");
        }
        else {
            setaccordionClass("menu-item menu-accordion");
            setsubAccordionClass("menu-sub menu-sub-accordion");
        }
    }
    useEffect(update, [location, children]);

    if (href)
        return (
            <div className="menu-item" onClick={() => onClick?.()}>
                <NavLink className="menu-link" to={href as string}>
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">{name}</span>
                </NavLink>
            </div>
        );
    else if (children) {
        return (
            <div data-kt-menu-trigger="click" className={accordionClass} onClick={() => onClick?.()}>
                <span className="menu-link">
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">{name}</span>
                    <span className="menu-arrow"></span>
                </span>
                <div className={subSccordionClass} kt-hidden-height="250">
                    {children}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="menu-item" onClick={() => onClick?.()}>
                <span className="menu-link">
                    <span className="menu-bullet">
                        {icon ? <i className={"text-hover-primary " + icon}></i> : <span className="bullet bullet-dot"></span>}
                </span>
                    <span className={size ? "menu-title fs-" + size : "menu-title"}>{name}</span>
                </span>
            </div>
        );
    }
}