import { useContext, useEffect, useState } from 'react';
import { PageLayout } from '../components/layouts/PageLayout';
import 'rc-tree/assets/index.css'
import Tree from 'rc-tree';
import { ApplicationContext } from '../services/ContextService';
import { apiService, Space } from '../services/ApiService';

export function Hierarchy() {
    const ctx = useContext(ApplicationContext);

    const [treeData, setTreeData] = useState<any[]>([]);

    const renderTreeData = (tree: any, key: any, children: any) =>
    {
        const loop = (data: any) => {
            data.forEach((item: any) => {
                if (item.children)
                    loop(item.children);

                if (key == item.key)
                    item.children = children;
            });
        }
        loop(tree);
    }
    const onLoadData = (treeNode: any) => {
        return new Promise<void>(async resolve => {
            const _data = await apiService.getHierachy(ctx.organizationId as string, treeNode.props.eventKey);
            const _children = _data.map(r => {
                return {
                    title: r.name,
                    key: r.id,
                    icon: r.icon ? <i className={"bi bi-" + r.icon} style={{ color: r.color }}></i> : undefined
                }
            });

            const _treeData = [...treeData] as any;
            renderTreeData(_treeData, treeNode.props.eventKey, _children);
            setTreeData(_treeData);

            resolve();
        });
    };

    useEffect(() => {
        Promise.resolve(apiService.getHierachy(ctx.organizationId as string)).then(res => {
            setTreeData(res.map(r => {
                return {
                    title: r.name,
                    key: r.id,
                    icon: r.icon ? <i className={"bi bi-" + r.icon} style={{ color: r.color }}></i> : undefined
                }
            }));
        })
    }, []);

    return (
        <PageLayout name="Hierarchy" path={[{ name: "Dashboards" }]}>
            <Tree
                selectable={false}
                loadData={onLoadData}
                treeData={treeData}
            />
        </PageLayout>
        )
}