import { createContext, useContext, useState, useEffect, Children, ReactElement } from 'react';
import { Link } from 'react-router-dom'
import { ApplicationContext } from '../../services/ContextService'

type ToolbarProps = {
    children?: [React.ReactElement[]];
};

export const Toolbar: React.FC<ToolbarProps> = ({ children }) => {
	const [name, setname] = useState<string>("");
	const [path, setpath] = useState<{ name: string, href?: string }[]>([]);
	const [items, setitems] = useState<ReactElement>(<></>)

	const ctx = useContext(ApplicationContext);

	ctx.toolbar = {
		setName: (name: string) => setname(name),
		setPath: (items: { name: string, href?: string }[]) => setpath(items),
		setItems: (content: ReactElement) => setitems(content)
	}

    return (
		<div id="kt_app_toolbar" className="app-toolbar py-4 py-lg-8" data-kt-sticky="true" data-kt-sticky-name="app-toolbar-sticky" data-kt-sticky-offset="{default: 'false', lg: '300px'}">
			<div id="kt_app_toolbar_container" className={ctx.fluid ? "app-container container-fluid d-flex flex-stack flex-wrap" : "app-container container-xxl d-flex flex-stack flex-wrap"}>
				<div className="page-title d-flex py-2 py-lg-0 flex-column justify-content-center me-3">
					<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{name}</h1>
					<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
						{path.map((item, i) => {
							if (item.href)
								return i < path.length -1 ? (
									<>
										<li key={i*2} className="breadcrumb-item text-muted">
											<Link className="text-muted text-hover-primary" to={item.href}>{item.name}</Link>
										</li>
										<li key={(i*2)+1} className="breadcrumb-item">
											<span className="bullet bg-gray-400 w-5px h-2px"></span>
										</li>
									</>
								) : (
										<li key={i * 2} className="breadcrumb-item text-muted">
										<Link className="text-muted text-hover-primary" to={item.href}>{item.name}</Link>
									</li>
								)
							else
								return i < path.length -1 ? (
									<>
										<li key={i*2} className="breadcrumb-item text-muted">{item.name}</li>
										<li key={(i * 2) + 1} className="breadcrumb-item">
											<span className="bullet bg-gray-400 w-5px h-2px"></span>
										</li>
									</>
								) : (
										<li key={i * 2} className="breadcrumb-item text-muted">{item.name}</li>
								)
						})}
					</ul>
				</div>
				<div className="d-flex align-items-center gap-2 gap-lg-3">
					{items}
				</div>
			</div>
		</div>
    );
}