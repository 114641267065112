import { useState, useEffect, useRef, useCallback } from 'react';
import { apiService, FileInfo } from '../../services/ApiService';
import { useDropzone } from 'react-dropzone'
import './filepicker.css'

type FilePickerProps = {
    organizationId: string,
    value?: FileInfo,
    onChange?: (value: FileInfo) => void;
    accept?: string | string[] | undefined
};

export const FilePicker: React.FC<FilePickerProps> = ({ organizationId, value, onChange, accept }) => {
    const [files, setFiles] = useState<FileInfo[]>([])
    const [file, setFile] = useState<string>()
    const [disabled, setDisabled] = useState<boolean>()
    const [error, setError] = useState<boolean>()

    useEffect(() => {
        Promise.resolve(apiService.getFiles(organizationId)).then((res) => {
            setFiles(res);
            if (res.length > 0)
                if (!value || value.id.length == 0)
                    setFile(res[0].id);
        });
    }, [])
    useEffect(() => {
        if (value)
            setFile(value.id);
    }, [value]);
    useEffect(() => {
        const _file = files.find(f => f.id === file);
        if (_file)
            onChange?.(_file);
    }, [file])

    const onDrop = useCallback((upload: File[]) => {
        setError(false);
        setDisabled(true);
        if (upload.length <= 0)
            return;

        const _uploads = [] as Promise<FileInfo>[]
        upload.forEach(u => {
            _uploads.push(Promise.resolve<FileInfo>(apiService.uploadFile(organizationId, u)))
        })

        Promise.all(_uploads).then(fileinfo => {
            const _files = [...files, ...fileinfo];

            setFiles(_files);
            setFile(fileinfo[fileinfo.length - 1].id);

            setDisabled(false);
        }).catch(() => {
            setError(true);
            setDisabled(false)
        })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: accept ?? undefined, maxFiles: 10 })

    const acceptText = Array.isArray(accept) ?
        (accept as string[]).join(', ') : accept;
    const _text = accept ? 'Upload up to 10 ' + acceptText + ' files. Files are automaticly shared with everyone in your organization.' : 'Upload up to 10 files. Files are automaticly shared with everyone in your organization.';

    return (
        <div className="filepicker">
            <select className="form-select form-select-sm form-select-solid" value={file} onChange={(e) => setFile(e.target.value)} disabled={disabled} >
                {files.map((e, key) => {
                    return <option key={key} value={e.id}>{e.name}</option>;
                })}
            </select>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    error ?
                        <div className="dropzone dz-clickable bg-light-danger border-danger">
                            <div className="dz-message needsclick">
                                <i className="bi bi-exclamation-triangle text-danger fs-3x"></i>
                                <div className="ms-4">
                                    <h4 className="fs-5 fw-bold text-gray-900 mb-1">File upload has failed.</h4>
                                    <span className="fs-7 fw-semibold text-danger opacity-75">{_text}</span>
                                </div>
                            </div>
                        </div> :
                    disabled ?
                        <div className="dropzone dz-clickable dz-drag-hover">
                                <div className="dz-message needsclick align-middle">
                                    <span className="spinner-border text-primary fs-3x" role="status"></span>
                                <div className="ms-4">
                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                                    <span className="fs-7 fw-semibold text-primary opacity-75">{_text}</span>
                                </div>
                            </div>
                        </div> :
                    isDragActive ?
                        <div className="dropzone dz-clickable dz-drag-hover">
                            <div className="dz-message needsclick">
                                <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                                <div className="ms-4">
                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                                    <span className="fs-7 fw-semibold text-primary opacity-75">{_text}</span>
                                </div>
                            </div>
                        </div> :
                        <div className="dropzone dz-clickable">
                            <div className="dz-message needsclick">
                                <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                                <div className="ms-4">
                                    <h4 className="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h4>
                                    <span className="fs-7 fw-semibold text-primary opacity-75">{_text}</span>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}