import { useState, useEffect, useRef, useCallback, useMemo, ReactElement } from 'react';
import uuid from 'react-uuid'

export interface LabelPickerValue {
    name?: string;
    color?: string;
};

type LabelPickerProps = LabelPickerValue & {
    onChange?: (value: LabelPickerValue) => void;
};
type MultiLabelPickerProps = {
    values?: LabelPickerValue[];
    onChange?: (value: LabelPickerValue[]) => void;
};

export const LabelPicker: React.FC<LabelPickerProps> = ({ name, color, onChange }) => {
    const [nameValue, setNameValue] = useState<string>(name ?? '')
    const [colorValue, setColorValue] = useState<string>(color ?? '#4bacc6')

    useEffect(() => {
        onChange?.({ name: nameValue, color: colorValue })
    }, [nameValue, colorValue])

    return (
        <div className="input-group input-group-sm">
            <input type="text" className="form-control" placeholder="Value" value={nameValue} onChange={(e) => setNameValue(e.target.value)} />
            <input type="color" className="form-control form-control-sm colorpicker" id="exampleColorInput" value={colorValue} onChange={(e) => setColorValue(e.target.value)} title="Choose your color"></input>
        </div>
    );
};

export const MultiLabelPicker: React.FC<MultiLabelPickerProps> = ({ values, onChange }) => {
    const [items, setItems] = useState<{ id: string, value: LabelPickerValue }[]>(values?.map(v => { return { id: uuid(), value: v } }) ?? []);

    const colors = ['#4bacc6', '#ffbd67', '#613864', '#4dc771', '#ff6666', '#174897']

    const handleAdd = () => {
        setItems([...items, { id: uuid(), value: { color: colors[(items.length % colors.length)] } }]);
    };
    const handleRemove = (id: string) => {
        setItems(items.filter(item => item.id !== id));
    };
    const handleValue = (id: string, value: LabelPickerValue) => {
        const item = items.find(i => i.id === id);
        if (item)
            item.value = value;

        onChange?.(items.map(item => item.value));
    }

    useEffect(() => {
        onChange?.(items.map(item => item.value));
    }, [items])

    useMemo(() => {
        if (values)
            if (values.length != items.length ||
                !values.every(v =>
                    items.findIndex(i =>
                        i.value.name === v.name &&
                        i.value.color === v.color) > -1
                    ))
                setItems(values.map(v => { return { id: uuid(), value: v } }) ?? []);
    }, [values])

    return (
        <div>
            {items.map(item => (
                <div key={item.id} className="d-flex mb-3">
                    <LabelPicker name={item.value.name} color={item.value.color} onChange={(value) => handleValue(item.id, value)} ></LabelPicker>
                    <a className="btn btn-custom btn-sm btn-icon btn-color-danger bg-body" onClick={() => handleRemove(item.id)}><i className="bi bi-trash"></i></a>
                </div>
            ))}
            <a className="btn btn-custom btn-sm btn-icon btn-primary" onClick={handleAdd}><i className="bi bi-plus"></i></a>
        </div>
    )
}