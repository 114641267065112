import moment from 'moment';
import { forwardRef, useContext, useEffect, useId, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardCreate } from '../components/dashboard';
import { DateRangePicker } from '../components/forms/datepicker';
import { PageLayout } from '../components/layouts/PageLayout';
import { apiService, Dashboard as DashboardType } from '../services/ApiService';
import { ApplicationContext } from '../services/ContextService';
import { Widget, WidgetContainer, WidgetContainerRef } from '../widgets';
import './Dashboard.css';

declare global {
    interface Window {
        KTMenu: {
            init: () => void;
            getInstance: (element: any) => any;
            hideDropdowns: () => void;
        };
    }
}

export type DashboardItemsProps = {
    addFunction: (type: string) => void;
    setFunction: (start: Date, end: Date, interval: number) => void;
    refreshFunction: (start: Date, end: Date) => void;
    saveFunction: () => void;
    owner: boolean;
};
export type DashboardItemsRef = {
    isSaving: (saving: boolean, success: boolean) => void;
    isRefreshing: (refreshing: boolean) => void;
};
const DashboardItems = forwardRef<DashboardItemsRef, DashboardItemsProps>(({ addFunction, setFunction, refreshFunction, saveFunction, owner }, ref) => {
    const [saving, setSaving] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

    const uuid = useId();

    const [intervalActive, setintervalActive] = useState<number>(60000);
    const intevalActiveClass = "btn btn-outline-primary btn-sm btn-color-light btn-bg-primary"
    const intevalInactiveClass = "btn btn-custom btn-sm btn-color-primary bg-body"

    const [spanStart, setspanStart] = useState<Date>(moment().startOf('day').toDate());
    const [spanEnd, setspanEnd] = useState<Date>(moment().endOf('day').toDate());
    const spanChange = (start: Date, end: Date) => { setspanStart(start); setspanEnd(end); }

    const ctx = useContext(ApplicationContext);
    const _clearDashboardFn = () => {
        setTimeout(() => {
            ctx.portal?.current?.setContent(<></>);
        }, 500);
    }
    const _createDashboardFn = () => {
        ctx.portal?.current?.setContent(<DashboardCreate onCancel={_clearDashboardFn} onSave={_clearDashboardFn}></DashboardCreate>);
    };

    useImperativeHandle(ref, () => ({
        isSaving(saving: boolean, success: boolean) {
            setSaving(saving);
            if (!saving) {
                setSaveError(!success);
                setSaveSuccess(success);
            } else {
                setSaveError(false);
                setSaveSuccess(false);
            }
            setTimeout(() => {
                setSaveSuccess(false);
            }, 5000);
        },
        isRefreshing(refreshing: boolean) {
            setRefreshing(refreshing);
        }
    }));

    useEffect(() => {
        window.KTMenu.init();
        var menuEl = document.querySelector('#' + CSS.escape(uuid));
        var menu = window.KTMenu.getInstance(menuEl);
        menu.on("kt.menu.link.click", function (link: Element) {
            if (link.hasAttribute('data-widget-add')) {
                if (owner)
                    addFunction(link.getAttribute('data-widget-add') ?? '')
            }
            if (link.hasAttribute('data-dashboard-add')) {
                _createDashboardFn();
            }
        });
    }, []);
    useEffect(() => () => {
        window.KTMenu.hideDropdowns();
    }, []);

    useEffect(() => {
        setFunction?.(spanStart, spanEnd, intervalActive);
    }, [spanStart, spanEnd, intervalActive])

    return (
        <>
            {saving ? 
                <div className="align-self-center text-gray-600 fw-norml">
                    <small className="align-middle text-small pe-1">Saving .. </small>
                    <small className="align-middle spinner-border text-primary" role="status"></small>
                </div>
                : <></>
            }
            {saveError ? 
                <div className="alert alert-danger align-items-center p-2 m-0" onClick={() => saveFunction() } style={{ cursor: 'pointer' }}>
                    <span><i className="bi bi-exclamation-circle text-danger pe-1"></i>Failed to save, click to try again.</span>
                </div>
                : <></>
            }
            {saveSuccess ? 
                <div className="alert alert-primary align-items-center p-2 m-0">
                    <span><i className="bi bi-check text-primary pe-1"></i>Dashboard saved.</span>
                </div>
                : <></>
            }
            <div className="btn-group" role="group">
                <button type="button" className="btn btn-custom btn-sm btn-icon btn-color-primary bg-body" disabled>
                    <span className="text-gray-600"><i className="bi bi-calendar"></i></span>
                </button>
                <DateRangePicker className="btn btn-custom btn-sm btn-color-primary bg-body" start={spanStart} end={spanEnd} max={new Date()} onChange={spanChange}></DateRangePicker>
            </div>
            <div className="btn-group" role="group">
                <button type="button" className="btn btn-custom btn-sm btn-icon btn-color-primary bg-body" disabled>
                    <span className="text-gray-600"><i className="bi bi-clock"></i></span>
                </button>
                <button type="button" className={intervalActive === 10000 ? intevalActiveClass : intevalInactiveClass} onClick={() => setintervalActive(10000)}>10s</button>
                <button type="button" className={intervalActive === 30000 ? intevalActiveClass : intevalInactiveClass} onClick={() => setintervalActive(30000)}>30s</button>
                <button type="button" className={intervalActive === 60000 ? intevalActiveClass : intevalInactiveClass} onClick={() => setintervalActive(60000)}>60s</button>
                <button type="button" className="btn btn-custom btn-sm btn-icon btn-color-primary bg-body" onClick={() => refreshFunction(spanStart, spanEnd)}>
                    <span className="text-gray-600"><i className={refreshing ? "bi bi-arrow-clockwise rotate" : "bi bi-arrow-clockwise"}></i></span>
                </button>
            </div>
            <button type="button" className="btn btn-custom btn-sm btn-icon btn-color-primary bg-body" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                <span className="text-gray-600"><i className="bi bi-plus-lg"></i></span>
            </button>
            <div id={uuid} className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
                <div className="menu-item px-3">
                    <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Create new</div>
                </div>
                <div className="separator mb-3 opacity-75"></div>
                <div className="menu-item px-3 mb-3">
                    <a className="menu-link px-3" data-dashboard-add>
                        Dashboard
                    </a>
                </div>
                {owner ? <>
                    <div className="menu-item px-3 mb-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <a className="menu-link px-3">
                            <span className="menu-title">Widget</span>
                            <span className="menu-arrow"></span>
                        </a>
                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                            <div className="menu-item px-3">
                                <a className="menu-link px-3" data-widget-add="objectviewer">
                                    Object Viewer
                                </a>
                            </div>
                            <div className="menu-item px-3">
                                <a className="menu-link px-3" data-widget-add="chart">
                                    Chart
                                </a>
                            </div>
                            <div className="menu-item px-3">
                                <a className="menu-link px-3" data-widget-add="status">
                                    Status
                                </a>
                            </div>
                        </div>
                    </div>
                </> : <></>}
            </div>
        </>
    )
})

export function Dashboard() {
    const ctx = useContext(ApplicationContext);
    let { dashboardId } = useParams();

    const [dashboard, setDashboard] = useState<DashboardType>({} as DashboardType);
    const dashboardRef = useRef<WidgetContainerRef>()
    const dashboardItemsRef = useRef<DashboardItemsRef>()
    
    const onDashboardChange = async () => {
        ctx.loading?.(true);

        await new Promise(resolve => setTimeout(resolve, 100));

        if (ctx.organizationId) {
            let _dashboard = await apiService.getDashboard(ctx.organizationId, dashboardId as string);
            setDashboard(_dashboard as DashboardType);
        }

        ctx.loading?.(false);
    }
    const onWidgetsChange = async (widgets: Widget[]) => {
        if (ctx.organizationId && dashboard.owner) {
            dashboardItemsRef?.current?.isSaving(true, true);
            const _saved = await apiService.editDashboard(ctx.organizationId, dashboardId as string, { ...dashboard, widgets: widgets });
            dashboardItemsRef?.current?.isSaving(false, _saved);
        }
    }
    const onWidgetsRefresh = (refreshing: boolean) => dashboardItemsRef?.current?.isRefreshing(refreshing);

    useEffect(() => { onDashboardChange() }, [dashboardId])
    
    return (
        <PageLayout
            name={dashboard?.name}
            path={[{ name: "Dashboards" }, { name: dashboard?.name }]}
            items={
                <DashboardItems
                    ref={t => dashboardItemsRef.current = t as DashboardItemsRef}
                    key={'di' + dashboard?.id}
                    addFunction={(type: string) => dashboardRef?.current?.addWidget(type)}
                    setFunction={(start: Date, end: Date, interval: number) => dashboardRef?.current?.setData(start, end, interval)}
                    refreshFunction={(start: Date, end: Date) => dashboardRef?.current?.refresh(start, end)}
                    saveFunction={() => dashboardRef?.current?.saveWidgets()}
                    owner={dashboard.owner}
                ></DashboardItems>
            }>
            <WidgetContainer
                key={'d' + dashboard?.id}
                ref={t => dashboardRef.current = t as WidgetContainerRef}
                widgets={dashboard?.widgets}
                owner={dashboard?.owner ?? false}
                onChange={onWidgetsChange}
                onRefresh={onWidgetsRefresh}
            ></WidgetContainer>
        </PageLayout>
    );
}