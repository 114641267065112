import * as BABYLON from '@babylonjs/core/Legacy/legacy';
import { CameraInteraction, IADTBackgroundColor, IADTObjectColor, SceneView, ViewerObjectStyle } from '@microsoft/iot-cardboard-js';
import { forwardRef, ReactElement, useEffect, useId, useImperativeHandle, useMemo, useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import uuid from 'react-uuid';
import { compileJsExpression, createDefaultJsExpressionContext } from 'sandboxed-javascript-expressions';
import { IWidget, Widget, WidgetTelemetry } from '../';
import { FilePicker } from '../../components/forms/filepicker';
import { MultiTelemetryPickerForMeshes, TelemetryForMeshesPickerProps } from '../../components/forms/telemetrypicker';
import { FileInfo, Telemetry } from '../../services/ApiService';
import { authService } from '../../services/AuthService';
import { ApplicationContext } from '../../services/ContextService';

declare global {
    interface Window {
        KTComponents: { init: () => void };
    }
}

interface ICameraPosition {
    position: BABYLON.Vector3;
    target: BABYLON.Vector3;
    radius: number;
}
interface CustomMeshItem {
    meshId: string;
    color?: string;
    transform?: TransformInfo;
}
interface TransformInfo {
    rotation: {
        x: number;
        y: number;
        z: number;
    };
    position: {
        x: number;
        y: number;
        z: number;
    };
}

export type ObjectViewerWidgetProps = {
    transparant?: 'yes' | 'no';
    file?: FileInfo;
    camera?: ICameraPosition;
    telemetry?: WidgetTelemetry[];
    [settings: string]: unknown;
};
export type ObjectViewerWidgetSettings = {
    id: string;
    configuration: TelemetryForMeshesPickerProps;
    meshes: string[];
};

export const ObjectViewerWidget: IWidget<ObjectViewerWidgetProps> = {
    Configure: ({ widget, onSave, onCancel }) => {
        const ctx = useContext(ApplicationContext);

        const [show, setShow] = useState(false);
        useEffect(() => setShow(true), []);

        const { id, title, type, position, transparant, file, camera, ...rest } = widget as Widget;

        const [_title, _setTitle] = useState<string>(title ?? '');
        const [_transparant, _setTransparant] = useState<string>(transparant as string ?? 'no');
        const [_file, _setFile] = useState<FileInfo | undefined>(file as FileInfo ?? undefined);
        const [_camera, _setCamera] = useState<ICameraPosition | undefined>(camera as ICameraPosition);
        const [_settings, _setSettings] = useState<ObjectViewerWidgetSettings[]>(rest.settings as ObjectViewerWidgetSettings[] ?? []);

        const handleClose = () => {
            onCancel?.();
            setShow(false);
        }
        const handleSave = () => {
            const _widget: Widget = {
                id: id,
                title: _title,
                type: 'objectviewer',
                position: position ?? {
                    w: 6,
                    h: 6,
                    x: 0,
                    y: 0,
                    minW: 4,
                    minH: 4
                },
                transparant: _transparant,
                file: _file,
                camera: _camera,
                telemetry: _settings.map(s => { return { id: s.configuration.capabilityId as string, name: '' } }),
                historical: false,
                settings: _settings
            }

            onSave?.(_widget);
            setShow(false);
        }

        const [objectColor, setobjectColor] = useState<IADTObjectColor>(transparant === 'yes' ? {
            color: '#4bacc6',
            baseColor: '#4bacc61A',
            lightingStyle: 1,
            coloredMeshColor: '#2087D6FF',
            meshHoverColor: '#8BC1E9FF',
            coloredMeshHoverColor: '#4AA7EEFF',
            outlinedMeshHoverColor: '#23FFFFFF',
            outlinedMeshSelectedColor: '#3595DEFF',
            outlinedMeshHoverSelectedColor: '#12C7E6FF',
            reflectionTexture: null
        } as any : {
            color: null,
            baseColor: null,
            lightingStyle: 1,
            coloredMeshColor: '#2087D6FF',
            meshHoverColor: '#8BC1E9FF',
            coloredMeshHoverColor: '#4AA7EEFF',
            outlinedMeshHoverColor: '#23FFFFFF',
            outlinedMeshSelectedColor: '#3595DEFF',
            outlinedMeshHoverSelectedColor: '#12C7E6FF',
            reflectionTexture: null
        } as any);
        const [outlinedMeshitems, setoutlinedMeshitems] = useState<CustomMeshItem[]>([]);
        const [selectedMeshitems, setSelectedMeshitems] = useState<CustomMeshItem[]>([]);

        const [activeColor, setActiveColor] = useState<string>('#4bacc6');
        const [markers, setMarkers] = useState<any[] | undefined>(undefined);

        useEffect(() => {
            setobjectColor(_transparant === 'yes' ?
                {
                    ...objectColor, color: '#4bacc6',
                    baseColor: '#4bacc61A'
                } as any : {
                    ...objectColor,
                    color: null,
                    baseColor: null
                } as any);

            setSelectedMeshitems(selectedMeshitems.map(c => { return { ...c, color: activeColor } }));
        }, [_transparant, activeColor])
        const meshHover = (
            mesh: BABYLON.AbstractMesh,
            scene: BABYLON.Scene,
            pointerEvent: PointerEvent
        ) => {
            if (!active)
                return;

            setoutlinedMeshitems(mesh ? [{ meshId: mesh?.id, color: activeColor }] as any : []);
        };
        const meshClick = (
            mesh: BABYLON.AbstractMesh,
            scene: BABYLON.Scene,
            pointerEvent: PointerEvent
        ) => {
            if (!active)
                return;

            const _selected = selectedMeshitems.find(m => m.meshId === mesh.id) ?
                selectedMeshitems.filter(m => m.meshId !== mesh.id) :
                [...selectedMeshitems, { meshId: mesh.id, color: activeColor }];

            setSelectedMeshitems(_selected);
           
            const _active = _settings.find(i => i.id === active?.id);
            if (_active)
                _setSettings(_settings.map(s => {
                    if (s.id !== _active.id)
                        return s;
                    else
                        return { ...s, meshes: _selected.map(s => s.meshId) };
                }))
        };

        const [active, setActive] = useState<TelemetryForMeshesPickerProps | undefined>(undefined);
        useEffect(() => {
            if (!active)
                return;

            setoutlinedMeshitems([]);
            setActiveColor(active.color as string);

            if (selectedMeshitems && selectedMeshitems.length > 0)
                setMarkers([{
                    id: 'ID',
                    attachedMeshIds: selectedMeshitems.map(m => m.meshId),
                    UIElement: <div className="rounded-pill p-2 d-inline-flex align-items-center" style={{
                        backgroundColor: activeColor + 'ff',
                        outline: '3px solid #ffffff50'
                    }}>
                        <i className={active.icon + " fs-4 text-inverse-danger"}></i>
                    </div>,
                    allowGrouping: false,
                    showIfOccluded: true
                }]);
            else
                setMarkers([]);
        }, [active, selectedMeshitems])
        useEffect(() => {
            if (!active) {
                setoutlinedMeshitems([]);
                setSelectedMeshitems([]);
                setMarkers([]);
                return;
            }

            const _item = _settings.find(i => i.id === active?.id)
            if (_item)
                setSelectedMeshitems(_item.meshes.map(i => { return { meshId: i, color: activeColor } }));
            else
                setSelectedMeshitems([]);
        }, [active]);

        const onChange = (value: TelemetryForMeshesPickerProps[]) => {
            if (value)
                _setSettings(
                    value.map(v => {
                        const _existing = _settings.find(s => s.id === v.id);
                        if (_existing)
                            return { ..._existing, configuration: v }
                        else
                            return {id: v.id ?? uuid(), configuration: v, meshes: []};
                    })
                );
        }

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Object Viewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-7">
                            <Form.Label className="required fs-6 fw-semibold form-label mb-2">Title</Form.Label>
                            <Form.Control
                                className="form-control-solid"
                                size="sm"
                                type="text"
                                placeholder="Enter title .."
                                value={_title}
                                onChange={(e) => { _setTitle(e.target.value) }}
                                autoFocus
                            />
                        </Form.Group>
                        <div className="mb-7">
                            <label className="required fs-6 fw-semibold form-label mb-2">File</label>
                            <FilePicker organizationId={ctx.organizationId as string} value={_file} onChange={(value) => _setFile(value)} accept=".glb"></FilePicker>
                        </div>
                        <div className="mb-7 form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" checked={_transparant === 'yes'} onChange={(event) => _setTransparant(event.target.checked ? 'yes' : 'no')} />
                            <label className="form-check-label" htmlFor="dashboard_name">
                                Transparant
                            </label>
                        </div>
                        {
                            _file ? 
                                <div className="mb-7">
                                    <SceneView
                                        key={'cnf' + id}
                                        allowModelDimensionErrorMessage={false}
                                        backgroundColor={{
                                            color: 'radial-gradient(#2f3c61 0%, #16203c 70%)',
                                            badgeColor: '#2f3c61',
                                            defaultBadgeColor: '#000000',
                                            defaultBadgeTextColor: '#ffffff',
                                            aggregateBadgeColor: '#ffffff',
                                            aggregateBadgeTextColor: '#000000',
                                            objectLuminanceRatio: 1.2
                                        } as IADTBackgroundColor}
                                        objectColor={objectColor}
                                        objectStyle={ViewerObjectStyle.Default}
                                        modelUrl={_file ? '/api/organizations/' + ctx.organizationId + '/files/' + _file.name : undefined}
                                        getToken={() => authService.getToken("pulse")}
                                        onMeshHover={meshHover}
                                        onMeshClick={meshClick}
                                        outlinedMeshitems={outlinedMeshitems as any}
                                        coloredMeshItems={selectedMeshitems as any}
                                        showHoverOnSelected={false}
                                        showMeshesOnHover={true}
                                        unzoomedMeshOpacity={1}
                                        cameraPosition={camera as ICameraPosition ?? undefined}
                                        onCameraMove={(position) => _setCamera(position)}
                                        cameraInteractionType={CameraInteraction.Rotate}
                                        markers={markers}
                                    />
                                </div> :
                                <></>
                        }
                        <div className="mb-7">
                            <label className="required fs-6 fw-semibold form-label mb-2">Telemetry</label>
                            <MultiTelemetryPickerForMeshes organizationId={ctx.organizationId as string} values={_settings.map(s => s.configuration)} onChange={onChange} onActive={(active) => setActive(active) } ></MultiTelemetryPickerForMeshes>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" size="sm" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            )
    },
    Render: forwardRef((props, ref) => {
        const ctx = useContext(ApplicationContext);

        const sceneId = useId();
        const { transparant, file, camera, telemetry, ...rest } = props as ObjectViewerWidgetProps;

        const objectColor = useMemo(() => transparant === 'yes' ? {
            color: '#4bacc6',
            baseColor: '#4bacc61A',
            lightingStyle: 1,
            coloredMeshColor: '#2087D6FF',
            meshHoverColor: '#8BC1E9FF',
            coloredMeshHoverColor: '#4AA7EEFF',
            outlinedMeshHoverColor: '#23FFFFFF',
            outlinedMeshSelectedColor: '#3595DEFF',
            outlinedMeshHoverSelectedColor: '#12C7E6FF',
            reflectionTexture: null
        } as any : {
            color: null,
            baseColor: null,
            lightingStyle: 1,
            coloredMeshColor: '#2087D6FF',
            meshHoverColor: '#8BC1E9FF',
            coloredMeshHoverColor: '#4AA7EEFF',
            outlinedMeshHoverColor: '#23FFFFFF',
            outlinedMeshSelectedColor: '#3595DEFF',
            outlinedMeshHoverSelectedColor: '#12C7E6FF',
            reflectionTexture: null
        } as any, [transparant]);
        const [coloredMeshitems, setcoloredMeshitems] = useState<CustomMeshItem[]>([]);
        const [markers, setMarkers] = useState<any[] | undefined>(undefined);
        const [cameraPosition, setCameraPosition] = useState<ICameraPosition | undefined>(camera)

        const _settings = useMemo(() => rest.settings as ObjectViewerWidgetSettings[] ?? [], [rest.settings]);
        const arrayEquals = (a: any, b: any) => {
            return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index]);
        }
        
        useImperativeHandle(ref, () => ({
            onData(data: Telemetry[]) {
                const _coloredMeshitems = [] as CustomMeshItem[];
                const _markers = [] as { meshes: string[], elements: ReactElement[] }[];

                data.forEach((t) => {
                    const _interested = _settings.filter(s => s.configuration.capabilityId == t.id);
                    _interested.forEach(i => {
                        if (!i.meshes || i.meshes.length <= 0)
                            return;

                        let color = i.configuration.color ?? '#4bacc6';
                        try {
                            let _expression = compileJsExpression(i.configuration.evaluate as string);
                            let _scope = {
                                values: t.data.map(v => v.value),
                                value: t.data[t.data.length -1].value,
                                data: t.data
                            };
                            let _context = createDefaultJsExpressionContext(_scope);
                            let _result = _expression(_context);

                            if (_result === true)
                            {
                                _coloredMeshitems.push.apply(_coloredMeshitems, i.meshes.map(m => { return { meshId: m, color: color } }));

                                const _existingIndex = _markers.findIndex(m => arrayEquals(m.meshes, i.meshes));
                                if (_existingIndex >= 0) {
                                    _markers[_existingIndex].elements.push(
                                        <OverlayTrigger
                                            key={'ol' + _existingIndex} 
                                            placement="top"
                                            overlay={<Tooltip><i className={i.configuration.icon + ' pe-1'} style={{ color: i.configuration.color }}></i>{t.data[t.data.length - 1].value}{t.unit ?? ''}</Tooltip>}
                                        >
                                            <div
                                                className="rounded-pill p-2 d-inline-flex align-items-center"
                                                style={{
                                                    backgroundColor: i.configuration.color + 'ff',
                                                    outline: '3px solid #ffffff50'
                                                }}
                                            >
                                                <i className={i.configuration.icon + " fs-4 text-inverse-danger"}></i>
                                            </div>
                                        </OverlayTrigger>
                                    );
                                }
                                else {
                                    _markers.push({
                                        meshes: i.meshes,
                                        elements: [
                                            <OverlayTrigger
                                                key={'ol' + 0} 
                                                placement="top"
                                                overlay={<Tooltip><i className={i.configuration.icon + ' pe-1'} style={{ color: i.configuration.color }}></i>{t.data[t.data.length - 1].value}{t.unit ?? ''}</Tooltip>}
                                            >
                                                <div
                                                    className="rounded-pill p-2 d-inline-flex align-items-center"
                                                    style={{
                                                        backgroundColor: i.configuration.color + 'ff',
                                                        outline: '3px solid #ffffff50'
                                                    }}
                                                >
                                                    <i className={i.configuration.icon + " fs-4 text-inverse-danger"}></i>
                                                </div>
                                            </OverlayTrigger>
                                        ]
                                    });
                                }
                            }
                        }
                        catch {
                            console.warn('Invalid evaluation: ' + i.configuration.evaluate)
                        }
                    })
                    setcoloredMeshitems(_coloredMeshitems);
                    setMarkers(_markers.map((m, index) => {
                        return {
                            id: index,
                            attachedMeshIds: m.meshes,
                            UIElement:
                                <div key={index} style={{ width: '60px', textAlign: 'center' }}>{m.elements}</div>,
                            allowGrouping: false,
                            showIfOccluded: true
                        }
                    }));
                });

                window.KTComponents.init();
            }
        }));

        return (
            <SceneView
                key={sceneId}
                allowModelDimensionErrorMessage={false}
                backgroundColor={{
                    color: 'radial-gradient(#2f3c61 0%, #16203c 70%)',
                    badgeColor: '#2f3c61',
                    defaultBadgeColor: '#000000',
                    defaultBadgeTextColor: '#ffffff',
                    aggregateBadgeColor: '#ffffff',
                    aggregateBadgeTextColor: '#000000',
                    objectLuminanceRatio: 1.2
                } as IADTBackgroundColor}
                objectColor={objectColor}
                objectStyle={ViewerObjectStyle.Default}
                modelUrl={file ? '/api/organizations/' + ctx.organizationId + '/files/' + file.name : undefined}
                getToken={() => authService.getToken("pulse")}
                coloredMeshItems={coloredMeshitems as any}
                unzoomedMeshOpacity={1}
                cameraPosition={cameraPosition}
                cameraInteractionType={CameraInteraction.Rotate}
                markers={markers}
            />
        );
    })
}
