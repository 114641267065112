import { Component } from 'react';
import { Link } from 'react-router-dom'
import lightLogo from '../../assets/logos/logo-light.svg'
import { SignInButton } from '../../components/profilemenu/SignIn'

type Props = {
	children?: React.ReactNode;
};

export class LayoutUnauthenticated extends Component<Props> {
	static displayName = LayoutUnauthenticated.name;

    render() {
        return (
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center bg-primary">
					<div className="d-flex flex-column flex-center p-6 p-lg-10 w-100">
						<a href="/" className="mb-0 mb-lg-20">
							<img alt="Logo" src={lightLogo} className="h-40px h-lg-50px"></img>
						</a>
						<h1 className="d-none d-lg-block text-white fs-2qx fw-bold text-center mb-7">SmartBuilding</h1>
						<div className="d-none d-lg-block text-white fs-base text-center">
							Welcome to Pulse SmartBuilding. Please sign in to continue.<br />If you would like to register, please contact <Link className="link-secondary" to='https://avex-int.com'>AVEX International</Link>.
						</div>
					</div>
				</div>
					<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10">
						<div className="d-flex flex-center flex-column flex-lg-row-fluid">
							<div className="w-lg-500px p-10">
								<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_sign_in_form" data-kt-redirect-url="/" action="#">
									<div className="text-center mb-11">
										<h1 className="text-dark fw-bolder mb-3">Sign In</h1>
									</div>
									<div className="row g-3 mb-9">
										<div className="col-md-">
											<SignInButton></SignInButton>
										</div>
									</div>
									<div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet? <a href="#" className="link-primary">Sign up</a></div>
								</form>
							</div>
						</div>
						<div className="d-flex flex-center flex-wrap px-5">
							<div className="d-flex fw-semibold text-primary fs-base">
								<a href="#" className="px-5" target="_blank">Terms</a>
								<a href="#" className="px-5" target="_blank">Plans</a>
								<a href="#" className="px-5" target="_blank">Contact Us</a>
							</div>
						</div>
					</div>
				</div>
    );
  }
}
