import { Children, Component } from 'react';
import { NavLink } from 'react-router-dom';

export class ProfileMenuSeperator extends Component {
    static displayName = ProfileMenuSeperator.name;


    render() {
        return (
            <div className="separator my-2"></div>
        );
  }
}
