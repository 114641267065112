import { Dashboard } from "./pages/Dashboard";
import { Home } from "./pages/Home";
import { Hierarchy } from "./pages/Hierarchy";

const AppRoutes = [
    {
        index: true,
        element: <Home></Home>
    },
    {
        path: '/dashboard/:dashboardId',
        element: <Dashboard />
    },
    {
        path: '/hierarchy',
        element: <Hierarchy />
    }
];

export default AppRoutes;
