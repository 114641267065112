import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { useState, useEffect, useId, ReactElement, useRef, createContext, forwardRef, useImperativeHandle } from 'react';
import { createPortal } from 'react-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";
import { LayoutAuthenticated } from './components/layouts/LayoutAuthenticated'
import { LayoutUnauthenticated } from './components/layouts/LayoutUnauthenticated'
import { ApplicationContext, ApplicationPortalProps, ApplicationPortalRef, appContext } from './services/ContextService'
import { apiService, Dashboard } from './services/ApiService'

declare global {
    interface Window {
        KTComponents: { init: () => void };
        KTApp: {
            showPageLoading: () => void;
            hidePageLoading: () => void;
        }
    }
}

function App() {
    const [organizationId, setOrganizationId] = useState<string | undefined>(undefined);
    const [dashboards, setDashboards] = useState<Dashboard[] | undefined>(undefined);

    appContext.portal = useRef<ApplicationPortalRef>();
    appContext.loading = (loading: boolean) => {
        if (loading)
            window.KTApp.showPageLoading();
        else
            window.KTApp.hidePageLoading();
    }
    appContext.organizationId = organizationId;
    appContext.setOrganizationId = (id: string) => setOrganizationId(id);
    appContext.dashboards = dashboards;
    appContext.setDashboards = (items: Dashboard[]) => setDashboards(items);

    useEffect(() => {
        if (organizationId)
            Promise.resolve(apiService.getDashboards(organizationId).then((res) => setDashboards(res)))
    }, [organizationId])
    useEffect(() => {
        window.KTComponents.init();
    }, []);

    return (
        <ApplicationContext.Provider value={appContext}>
            <div className="page-loader flex-column bg-dark bg-opacity-25">
                <span className="spinner-border text-primary" role="status"></span>
                <span className="text-gray-800 fs-6 fw-semibold mt-5">Loading...</span>
            </div>
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <AuthenticatedTemplate>
                        <LayoutAuthenticated>
                            <Routes>
                                {AppRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    return <Route key={index} {...rest} element={element} />;
                                })}
                            </Routes>
                        </LayoutAuthenticated>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <LayoutUnauthenticated></LayoutUnauthenticated>
                </UnauthenticatedTemplate>
            </div>
            <ApplicationPortal ref={p => (appContext.portal as React.MutableRefObject<ApplicationPortalRef | undefined>).current = p as ApplicationPortalRef}></ApplicationPortal>
        </ApplicationContext.Provider>
  );
}

const ApplicationPortal = forwardRef<ApplicationPortalRef, ApplicationPortalProps>((props, ref) => {
    const [portalContent, setPortalContent] = useState<JSX.Element>(<></>);

    useImperativeHandle(ref, () => ({
        setContent(content: JSX.Element) {
            setPortalContent(content);
        }
    }));

    return createPortal(portalContent, document.body
    );
});
export default App;
