import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginApiRequest, odataApiRequest, azureManagement, adx, storage, dt, user } from "../authConfig";

const Instance = new PublicClientApplication(msalConfig);

export default class AuthService {
    instance = Instance;
    user = () => Instance.getAllAccounts()[0];
    isAuthenticated = () => Instance.getAllAccounts().length > 0;
    login = () => {
        //if (Instance.getAllAccounts().length <= 0) {
        //    Instance.loginRedirect(loginApiRequest).catch((e) => {
        //        console.log(e);
        //    });
        //}
    };

    getToken = async (tokenFor?: 'azureManagement' | 'adx' | 'storage' | 'user' | 'pulse' | 'odata') => {
        const account = Instance.getAllAccounts()[0];
        if (tokenFor === 'azureManagement') {
            var token = await Instance.acquireTokenSilent({
                ...azureManagement,
                account: account,
            });
            return token.accessToken;
        } else if (tokenFor === 'adx') {
            var token = await Instance.acquireTokenSilent({
                ...adx,
                account: account,
            });
            return token.accessToken;
        } else if (tokenFor === 'storage') {
            var token = await Instance.acquireTokenSilent({
                ...storage,
                account: account,
            });
            return token.accessToken;
        } else if (tokenFor === 'user') {
            var token = await Instance.acquireTokenSilent({
                ...user,
                account: account,
            });
            return token.accessToken;
        } else if (tokenFor === 'pulse') {
            var token = await Instance.acquireTokenSilent({
                ...loginApiRequest,
                account: account,
            });
            return token.accessToken;
        } else if (tokenFor === 'odata') {
            var token = await Instance.acquireTokenSilent({
                ...odataApiRequest,
                account: account,
            });
            return token.accessToken;
        }
        else {
            var token = await Instance.acquireTokenSilent({
                ...dt,
                account: account,
            })
            return token.accessToken;
        }
    };
}

export const authService: AuthService = new AuthService();