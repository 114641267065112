import { useEffect, useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { AssideItemLink, AssideItemLinkProps } from '../asside';
import { apiService, Dashboard as DashboardType } from '../../services/ApiService';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicationContext } from '../../services/ContextService'

export type DashboardItemProps = {
	title: string | JSX.Element,
	subTitle?: string,
    children?: React.ReactElement<React.FC<AssideItemLinkProps>> | React.ReactElement<React.FC<AssideItemLinkProps>>[]
};

export const DashboardMenu = (items: DashboardType[]) => {
    const _dashboards = items.filter(d => !d.shared).map((d, i) => <AssideItemLink key={i} name={d.name} href={"/dashboard/" + d.id}></AssideItemLink>);
    const _shared = items.filter(d => d.shared).map((d, i) => <AssideItemLink key={i} name={d.name} href={"/dashboard/" + d.id}></AssideItemLink>);
    if (_shared.length)
        _dashboards.push(<AssideItemLink key={1000} name="Shared">{_shared}</AssideItemLink>)

    return _dashboards as React.ReactElement<React.FC<AssideItemLinkProps>>[];
}

export type DashboardCreateProps = {
	onSave?: (name: string, shared: boolean) => void;
	onCancel?: () => void;
};
export const DashboardCreate: React.FC<DashboardCreateProps> = ({ onSave, onCancel }) => {
	const ctx = useContext(ApplicationContext);

	const [show, setShow] = useState(false);
	useEffect(() => setShow(true), []);

	const navigate = useNavigate();

	const [_name, _setName] = useState<string>('');
	const [_shared, _setShared] = useState<boolean>(false);

	const handleClose = () => {
		onCancel?.();
		setShow(false);
	}
	const handleSave = async () => {
		if (ctx.organizationId)
		{
			const _dashboard = await apiService.addDashboard(ctx.organizationId, _name, _shared);
			const _dashboards = await apiService.getDashboards(ctx.organizationId);
			ctx.setDashboards?.(_dashboards);

			navigate("/dashboard/" + _dashboard.id);

			onSave?.(_name, _shared);
			setShow(false);
		}
	}
    return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Create new Dashboard</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-7">
						<Form.Label className="required fs-6 fw-semibold form-label mb-2">Title</Form.Label>
						<Form.Control
							className="form-control-solid"
							size="sm"
							type="text"
							placeholder="Enter title .."
							value={_name}
							onChange={(e) => { _setName(e.target.value) }}
							autoFocus
						/>
					</Form.Group>
					<div className="mb-7 form-check form-switch form-check-custom form-check-solid">
						<input className="form-check-input" type="checkbox" checked={_shared} onChange={(event) => _setShared(event.target.checked)} />
						<label className="form-check-label" htmlFor="dashboard_name">
							Shared
						</label>
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" size="sm" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" size="sm" onClick={handleSave}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
        )
}