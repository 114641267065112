import { loadavg } from 'os';
import { createContext, ReactElement } from 'react';
import { Dashboard} from './ApiService'

export interface IApplicationContext {
    fluid: boolean;
    loading?: (value: boolean) => void;
    toolbar?: {
        setName: (name: string) => void;
        setPath: (items: { name: string, href?: string }[]) => void;
        setItems: (content: ReactElement) => void;
    };
    portal?: React.MutableRefObject<ApplicationPortalRef | undefined>;
    organizationId?: string | undefined;
    setOrganizationId?: (id: string) => void;
    dashboards?: Dashboard[];
    setDashboards?: (items: Dashboard[]) => void;
}
export type ApplicationPortalProps = {};
export type ApplicationPortalRef = {
    setContent: (content: JSX.Element) => void;
};

export const appContext: IApplicationContext = {
    fluid: false,
    organizationId: undefined
};
export const ApplicationContext = createContext<IApplicationContext>(appContext);