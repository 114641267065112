import { useState, useEffect, useRef, useCallback, useMemo, ReactElement } from 'react';
import { apiService, Space, Meter, Capability } from '../../services/ApiService';
import uuid from 'react-uuid'
import { IconPicker } from './iconpicker'

import './telemetrypicker.css'
declare global {
    interface JQuery {
        ColorPicker: () => void;
    }
}

export interface TelemetryPickerValue {
    organizationId: string;
    spaceId?: string;
    meterId?: string;
    capabilityId?: string;
    name?: string;
};
export interface TelemetryPickerWithColorValue {
    organizationId: string;
    spaceId?: string;
    meterId?: string;
    capabilityId?: string;
    name?: string;
    color?: string;
};

export type TelemetryPickerProps = TelemetryPickerWithColorValue & {
    options?: Space[];
    onChange?: (value: TelemetryPickerWithColorValue) => void;
};
export type TelemetryForMeshesPickerProps = TelemetryPickerProps & {
    options?: Space[];
    id?: string;
    active?: boolean;
    activate?: () => void;
    icon?: string;
    evaluate?: string;
    onChange?: (value: TelemetryForMeshesPickerProps) => void;
};

type MultiTelemetryPickerProps = {
    organizationId: string;
    options?: Space[];
    values?: TelemetryPickerWithColorValue[];
    onChange?: (value: TelemetryPickerWithColorValue[]) => void;
};
type MultiTelemetryPickerForMeshesPickerProps = {
    organizationId: string;
    options?: Space[];
    values?: TelemetryForMeshesPickerProps[];
    onChange?: (value: TelemetryForMeshesPickerProps[]) => void;
    onActive?: (value?: TelemetryForMeshesPickerProps) => void;
};

function useInsideAlerter(ref: React.RefObject<any>, activate: () => void) {
    useEffect(() => {
        const listener = (event: TouchEvent | MouseEvent) => {
            if (!ref || !ref.current)
                return;

            if (ref.current && ref.current.contains(event.target))
                activate?.();
        }

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref]);
}

export const TelemetryPicker: React.FC<TelemetryPickerProps> = ({ options, organizationId, spaceId, meterId, capabilityId, name, onChange }) => {
    const [_options, _setOptions] = useState<Space[] | undefined>(options ?? undefined)

    const [spaceOptions, setSpaceOptions] = useState<Space[]>([])
    const [spaceIdValue, setSpaceIdValue] = useState<string>(spaceId ?? '')
    const [meterOptions, setMeterOptions] = useState<Meter[]>([])
    const [meterIdValue, setMeterIdValue] = useState<string>(meterId ?? '')
    const [capabilityOptions, setCapabilityOptions] = useState<Capability[]>([])
    const [capabilityIdValue, setCapabilityIdValue] = useState<string>(capabilityId ?? '')
    const [telemetryNameValue, setTelemetryNameValue] = useState<string>(name ?? '')

    useMemo(() => {
        if (_options) {
            setSpaceOptions(_options.map(s => {
                return { id: s.id, name: s.name } as Space;
            }))

            if (!spaceIdValue || spaceIdValue == '' || _options?.findIndex(o => o.id == spaceIdValue) == -1) {
                if (capabilityId && capabilityId != '') {
                    var _space = _options.find(s => s.meters?.find(m => m.capabilities?.find(c => c.id === capabilityId)));
                    if (!_space) {
                        setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
                        return;
                    }
                    var _meter = _space.meters?.find(m => m.capabilities?.find(c => c.id === capabilityId));
                    if (!_meter) {
                        setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
                        return;
                    }

                    setMeterIdValue(_meter.id);
                    setSpaceIdValue(_space.id);
                }
                else
                    setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
            }
        }
    }, [_options]);
    useMemo(() => {
        if (spaceIdValue && spaceIdValue !== '') {
            var _meters = _options?.find(s => s.id === spaceIdValue)?.meters;
            if (_meters) {
                setMeterOptions(_meters.map(m => {
                    return { id: m.id, name: m.name } as Meter;
                }))

                if (!meterIdValue || meterIdValue == '' || _meters.findIndex(m => m.id == meterIdValue) == -1) {
                    setMeterIdValue(_meters.length > 0 ? _meters[0].id : '');
                }
            }
        }
        else
            setMeterOptions([]);
    }, [spaceIdValue]);
    useMemo(() => {
        if (meterIdValue && meterIdValue !== '') {
            var _meters = _options?.find(s => s.id === spaceIdValue)?.meters;
            if (!_meters)
                return;

            var _capabilities = _meters?.find(s => s.id === meterIdValue)?.capabilities;
            if (_capabilities) {
                setCapabilityOptions(_capabilities.map(c => {
                    return { id: c.id, name: c.name } as Capability;
                }))

                if (!capabilityIdValue || capabilityIdValue == '' || _capabilities.findIndex(c => c.id == capabilityIdValue) == -1) {
                    setCapabilityIdValue(_capabilities.length > 0 ? _capabilities[0].id : '');
                }
            }
        }
        else
            setCapabilityOptions([]);
    }, [meterIdValue]);

    useEffect(() => {
        onChange?.({ organizationId: organizationId, spaceId: spaceIdValue, meterId: meterIdValue, capabilityId: capabilityIdValue, name: telemetryNameValue })
    }, [spaceIdValue, meterIdValue, capabilityIdValue, telemetryNameValue]);
    useEffect(() => {
        if (!_options)
            Promise.resolve(apiService.getTelemetryOptions(organizationId)).then(res => {
                _setOptions(res);
            });
    }, []);

    return (
        _options ?
            <div className="input-group input-group-sm">
                <select className="form-select" value={spaceIdValue} onChange={(e) => setSpaceIdValue(e.target.value)} >
                    {spaceOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <select className="form-select" value={meterIdValue} onChange={(e) => setMeterIdValue(e.target.value)} >
                    {meterOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <select className="form-select" value={capabilityIdValue} onChange={(e) => setCapabilityIdValue(e.target.value)} >
                    {capabilityOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <input type="text" className="form-control" placeholder="Name" value={telemetryNameValue} onChange={(e) => setTelemetryNameValue(e.target.value)} />
            </div> :
            <div className="menu-item">
                <div className="menu-content text-center">
                    <span className="spinner-border text-primary"></span>
                </div>
            </div>
    );
};
export const TelemetryPickerWithColor: React.FC<TelemetryPickerProps> = ({ options, organizationId, spaceId, meterId, capabilityId, name, color, onChange }) => {
    const [_options, _setOptions] = useState<Space[] | undefined>(options ?? undefined)

    const [spaceOptions, setSpaceOptions] = useState<Space[]>([])
    const [spaceIdValue, setSpaceIdValue] = useState<string>(spaceId ?? '')
    const [meterOptions, setMeterOptions] = useState<Meter[]>([])
    const [meterIdValue, setMeterIdValue] = useState<string>(meterId ?? '')
    const [capabilityOptions, setCapabilityOptions] = useState<Capability[]>([])
    const [capabilityIdValue, setCapabilityIdValue] = useState<string>(capabilityId ?? '')
    const [telemetryNameValue, setTelemetryNameValue] = useState<string>(name ?? '')
    const [colorValue, setColorValue] = useState<string>(color ?? '#4bacc6')

    useMemo(() => {
        if (_options) {
            setSpaceOptions(_options.map(s => {
                return { id: s.id, name: s.name } as Space;
            }))

            if (!spaceIdValue || spaceIdValue == '' || _options?.findIndex(o => o.id == spaceIdValue) == -1) {
                if (capabilityId && capabilityId != '') {
                    var _space = _options.find(s => s.meters?.find(m => m.capabilities?.find(c => c.id === capabilityId)));
                    if (!_space) {
                        setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
                        return;
                    }
                    var _meter = _space.meters?.find(m => m.capabilities?.find(c => c.id === capabilityId));
                    if (!_meter) {
                        setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
                        return;
                    }

                    setMeterIdValue(_meter.id);
                    setSpaceIdValue(_space.id);
                }
                else
                    setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
            }
        }
    }, [_options]);
    useMemo(() => {
        if (spaceIdValue && spaceIdValue !== '') {
            var _meters = _options?.find(s => s.id === spaceIdValue)?.meters;
            if (_meters) {
                setMeterOptions(_meters.map(m => {
                    return { id: m.id, name: m.name } as Meter;
                }))

                if (!meterIdValue || meterIdValue == '' || _meters.findIndex(m => m.id == meterIdValue) == -1) {
                    setMeterIdValue(_meters.length > 0 ? _meters[0].id : '');
                }
            }
        }
        else
            setMeterOptions([]);
    }, [spaceIdValue]);
    useMemo(() => {
        if (meterIdValue && meterIdValue !== '') {
            var _meters = _options?.find(s => s.id === spaceIdValue)?.meters;
            if (!_meters)
                return;

            var _capabilities = _meters?.find(s => s.id === meterIdValue)?.capabilities;
            if (_capabilities) {
                setCapabilityOptions(_capabilities.map(c => {
                    return { id: c.id, name: c.name } as Capability;
                }))

                if (!capabilityIdValue || capabilityIdValue == '' || _capabilities.findIndex(c => c.id == capabilityIdValue) == -1) {
                    setCapabilityIdValue(_capabilities.length > 0 ? _capabilities[0].id : '');
                }
            }
        }
        else
            setCapabilityOptions([]);
    }, [meterIdValue]);

    useEffect(() => {
        onChange?.({ organizationId: organizationId, spaceId: spaceIdValue, meterId: meterIdValue, capabilityId: capabilityIdValue, name: telemetryNameValue, color: colorValue })
    }, [spaceIdValue, meterIdValue, capabilityIdValue, telemetryNameValue, colorValue]);
    useEffect(() => {
        if (!_options)
            Promise.resolve(apiService.getTelemetryOptions(organizationId)).then(res => {
                _setOptions(res);
            });
    }, []);

    return (
        _options ?
            <div className="input-group input-group-sm">
                <select className="form-select" value={spaceIdValue} onChange={(e) => setSpaceIdValue(e.target.value)} >
                    {spaceOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <select className="form-select" value={meterIdValue} onChange={(e) => setMeterIdValue(e.target.value)} >
                    {meterOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <select className="form-select" value={capabilityIdValue} onChange={(e) => setCapabilityIdValue(e.target.value)} >
                    {capabilityOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <input type="text" className="form-control" placeholder="Name" value={telemetryNameValue} onChange={(e) => setTelemetryNameValue(e.target.value)} />
                <input type="color" className="form-control form-control-sm colorpicker" id="exampleColorInput" value={colorValue} onChange={(e) => setColorValue(e.target.value)} title="Choose your color"></input>
            </div> :
            <div className="menu-item">
                <div className="menu-content text-center">
                    <span className="spinner-border text-primary"></span>
                </div>
            </div>
    );
};
export const TelemetryPickerForMeshes: React.FC<TelemetryForMeshesPickerProps> = ({ id, active, activate, options, organizationId, spaceId, meterId, capabilityId, color, icon, evaluate, onChange }) => {
    const [_options, _setOptions] = useState<Space[] | undefined>(options ?? undefined)
    const _id = useMemo(() => { return id ?? uuid() }, [id])
    
    const [isActive, setActive] = useState<boolean>(active ?? false)
    const [spaceOptions, setSpaceOptions] = useState<Space[]>([])
    const [spaceIdValue, setSpaceIdValue] = useState<string>(spaceId ?? '')
    const [meterOptions, setMeterOptions] = useState<Meter[]>([])
    const [meterIdValue, setMeterIdValue] = useState<string>(meterId ?? '')
    const [capabilityOptions, setCapabilityOptions] = useState<Capability[]>([])
    const [capabilityIdValue, setCapabilityIdValue] = useState<string>(capabilityId ?? '')
    const [colorValue, setColorValue] = useState<string>(color ?? '#4bacc6')
    const [iconValue, setIconValue] = useState<string>(icon ?? 'bi bi-123')
    const [evaluateValue, setEvaluateValue] = useState<string>(evaluate ?? 'value >= 1')

    useMemo(() => {
        if (_options) {
            setSpaceOptions(_options.map(s => {
                return { id: s.id, name: s.name } as Space;
            }))

            if (!spaceIdValue || spaceIdValue == '' || _options?.findIndex(o => o.id == spaceIdValue) == -1) {
                if (capabilityId && capabilityId != '') {
                    var _space = _options.find(s => s.meters?.find(m => m.capabilities?.find(c => c.id === capabilityId)));
                    if (!_space) {
                        setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
                        return;
                    }
                    var _meter = _space.meters?.find(m => m.capabilities?.find(c => c.id === capabilityId));
                    if (!_meter) {
                        setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
                        return;
                    }

                    setMeterIdValue(_meter.id);
                    setSpaceIdValue(_space.id);
                }
                else
                    setSpaceIdValue(_options.length > 0 ? _options[0].id : '');
            }
        }
    }, [_options]);
    useMemo(() => {
        if (spaceIdValue && spaceIdValue !== '') {
            var _meters = _options?.find(s => s.id === spaceIdValue)?.meters;
            if (_meters) {
                setMeterOptions(_meters.map(m => {
                    return { id: m.id, name: m.name } as Meter;
                }))

                if (!meterIdValue || meterIdValue == '' || _meters.findIndex(m => m.id == meterIdValue) == -1) {
                    setMeterIdValue(_meters.length > 0 ? _meters[0].id : '');
                }
            }
        }
        else
            setMeterOptions([]);
    }, [spaceIdValue]);
    useMemo(() => {
        if (meterIdValue && meterIdValue !== '') {
            var _meters = _options?.find(s => s.id === spaceIdValue)?.meters;
            if (!_meters)
                return;

            var _capabilities = _meters?.find(s => s.id === meterIdValue)?.capabilities;
            if (_capabilities) {
                setCapabilityOptions(_capabilities.map(c => {
                    return { id: c.id, name: c.name } as Capability;
                }))

                if (!capabilityIdValue || capabilityIdValue == '' || _capabilities.findIndex(c => c.id == capabilityIdValue) == -1) {
                    setCapabilityIdValue(_capabilities.length > 0 ? _capabilities[0].id : '');
                }
            }
        }
        else
            setCapabilityOptions([]);
    }, [meterIdValue]);

    useEffect(() => {
        onChange?.({ id: _id, organizationId: organizationId, spaceId: spaceIdValue, meterId: meterIdValue, capabilityId: capabilityIdValue, color: colorValue, icon: iconValue, evaluate: evaluateValue })
    }, [spaceIdValue, meterIdValue, capabilityIdValue, colorValue, evaluateValue, iconValue]);
    useEffect(() => {
        if(active || active === false)
            setActive(active)
    }, [active]);
    useEffect(() => {
        if (!_options)
            Promise.resolve(apiService.getTelemetryOptions(organizationId)).then(res => {
                _setOptions(res);
            });
    }, []);

    const _ref = useRef<HTMLDivElement>(null);
    useInsideAlerter(_ref, () => activate?.());

    return (
        _options ?
            <div ref={_ref} className={isActive ? 'input-group input-group-sm active' : 'input-group input-group-sm'}>
                <select className="form-select" value={spaceIdValue} onChange={(e) => setSpaceIdValue(e.target.value)} >
                    {spaceOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <select className="form-select" value={meterIdValue} onChange={(e) => setMeterIdValue(e.target.value)} >
                    {meterOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <select className="form-select" value={capabilityIdValue} onChange={(e) => setCapabilityIdValue(e.target.value)} >
                    {capabilityOptions.map((e, key) => {
                        return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </select>
                <input type="text" className="form-control form-control-sm" placeholder="Evaluate" value={evaluateValue} onChange={(e) => setEvaluateValue(e.target.value)}></input>
                <input type="color" className="form-control form-control-sm colorpicker" id="exampleColorInput" value={colorValue} onChange={(e) => setColorValue(e.target.value)} title="Choose your color"></input>
                <IconPicker value={iconValue} onChange={(value) => setIconValue(value) }></IconPicker>
            </div> :
            <div className="menu-item">
                <div className="menu-content text-center">
                    <span className="spinner-border text-primary"></span>
                </div>
            </div>
    );
};

export const MultiTelemetryPicker: React.FC<MultiTelemetryPickerProps> = ({ organizationId, options, values, onChange }) => {
    const [_options, _setOptions] = useState<Space[] | undefined>(options ?? undefined)
    const [_items, _setItems] = useState<{ id: string, value: TelemetryPickerValue }[]>(values?.map(v => { return { id: uuid(), value: v } }) ?? []);

    const handleAdd = () => {
        _setItems([..._items, { id: uuid(), value: { organizationId: organizationId } }]);
    };
    const handleRemove = (id: string) => {
        _setItems(_items.filter(item => item.id !== id));
    };
    const handleValue = (id: string, value: TelemetryPickerValue) => {
        const item = _items.find(i => i.id === id);
        if (item)
            item.value = value;

        onChange?.(_items.map(item => item.value));
    }

    useEffect(() => {
        onChange?.(_items.map(item => item.value));
    }, [_items])
    useEffect(() => {
        if (!_options)
            Promise.resolve(apiService.getTelemetryOptions(organizationId)).then(res => {
                _setOptions(res);
            });
    }, []);
    useMemo(() => {
        if (values)
            if (values.length != _items.length ||
                !values.every(v =>
                    _items.findIndex(i =>
                        i.value.capabilityId === v.capabilityId &&
                        i.value.name === v.name) > -1
                    ))
                _setItems(values.map(v => { return { id: uuid(), value: v } }) ?? []);
    }, [values])

    return (
        _options ?
            <div>
                {_items.map(item => (
                    <div key={item.id} className="d-flex mb-3">
                        <TelemetryPicker options={_options} organizationId={organizationId} spaceId={item.value.spaceId} meterId={item.value.meterId} capabilityId={item.value.capabilityId} name={item.value.name} onChange={(value) => handleValue(item.id, value)} ></TelemetryPicker>
                        <a className="btn btn-custom btn-sm btn-icon btn-color-danger bg-body" onClick={() => handleRemove(item.id)}><i className="bi bi-trash"></i></a>
                    </div>
                ))}
                <a className="btn btn-custom btn-sm btn-icon btn-primary" onClick={handleAdd}><i className="bi bi-plus"></i></a>
            </div> :
            <div className="menu-item">
                <div className="menu-content text-center">
                    <span className="spinner-border text-primary"></span>
                </div>
            </div>
    )
}
export const MultiTelemetryPickerWithColor: React.FC<MultiTelemetryPickerProps> = ({ organizationId, options, values, onChange }) => {
    const [_options, _setOptions] = useState<Space[] | undefined>(options ?? undefined)
    const [_items, _setItems] = useState<{ id: string, value: TelemetryPickerWithColorValue }[]>(values?.map(v => { return { id: uuid(), value: v } }) ?? []);

    const colors = ['#4bacc6', '#ffbd67', '#613864', '#4dc771', '#ff6666', '#174897']

    const handleAdd = () => {
        _setItems([..._items, { id: uuid(), value: { organizationId: organizationId, color: colors[(_items.length % colors.length)]} }]);
    };
    const handleRemove = (id: string) => {
        _setItems(_items.filter(item => item.id !== id));
    };
    const handleValue = (id: string, value: TelemetryPickerWithColorValue) => {
        const item = _items.find(i => i.id === id);
        if (item)
            item.value = value;

        onChange?.(_items.map(item => item.value));
    }

    useEffect(() => {
        onChange?.(_items.map(item => item.value));
    }, [_items]);
    useEffect(() => {
        if (!_options)
            Promise.resolve(apiService.getTelemetryOptions(organizationId)).then(res => {
                _setOptions(res);
            });
    }, []);
    useMemo(() => {
        if (values)
            if (values.length != _items.length ||
                !values.every(v =>
                    _items.findIndex(i =>
                        i.value.capabilityId === v.capabilityId &&
                        i.value.name === v.name &&
                        i.value.color === v.color) > -1
                ))
                _setItems(values.map(v => { return { id: uuid(), value: v } }) ?? []);
    }, [values]);

    return (
        _options ?
            <div>
                {_items.map(item => (
                    <div key={item.id} className="d-flex mb-3">
                        <TelemetryPickerWithColor options={_options} organizationId={organizationId} spaceId={item.value.spaceId} meterId={item.value.meterId} capabilityId={item.value.capabilityId} name={item.value.name} color={item.value.color} onChange={(value) => handleValue(item.id, value)} ></TelemetryPickerWithColor>
                        <a className="btn btn-custom btn-sm btn-icon btn-color-danger bg-body" onClick={() => handleRemove(item.id)}><i className="bi bi-trash"></i></a>
                    </div>
                ))}
                <a className="btn btn-custom btn-sm btn-icon btn-primary" onClick={handleAdd}><i className="bi bi-plus"></i></a>
            </div> :
            <div className="menu-item">
                <div className="menu-content text-center">
                    <span className="spinner-border text-primary"></span>
                </div>
            </div>
    )
}
export const MultiTelemetryPickerForMeshes: React.FC<MultiTelemetryPickerForMeshesPickerProps> = ({ organizationId, options, values, onChange, onActive }) => {
    const [_options, _setOptions] = useState<Space[] | undefined>(options ?? undefined)
    const [_items, _setItems] = useState<{ id: string, value: TelemetryForMeshesPickerProps }[]>(values?.map(v => { return { id: uuid(), value: v } }) ?? []);
    const [_active, _setActive] = useState<string>('');

    const colors = ['#4bacc6', '#ffbd67', '#613864', '#4dc771', '#ff6666', '#174897']

    const handleAdd = () => {
        _setItems([..._items, { id: uuid(), value: { organizationId: organizationId, id: uuid(), icon: 'bi bi-123', color: colors[(_items.length % colors.length)] } }]);
    };
    const handleRemove = (id: string) => {
        _setItems(_items.filter(item => item.id !== id));
    };
    const handleValue = (id: string, value: TelemetryForMeshesPickerProps) => {
        const item = _items.find(i => i.id === id);
        if (item)
            item.value = value;

        onChange?.(_items.map(item => item.value));
        onActive?.(_items?.find(i => i.id === _active)?.value);
    }

    useEffect(() => {
        onChange?.(_items.map(item => item.value));

        if (!_items?.find(i => i.id === _active))
            _setActive(_items.length === 0 ? '' : _items[0].id)
    }, [_items]);
    useEffect(() => {
        onActive?.(_items?.find(i => i.id === _active)?.value);
    }, [_active]);
    useEffect(() => {
        if (!_options)
            Promise.resolve(apiService.getTelemetryOptions(organizationId)).then(res => {
                _setOptions(res);
            });
    }, []);
    useMemo(() => {
        if (values)
            if (values.length != _items.length ||
                !values.every(v =>
                    _items.findIndex(i =>
                        i.value.id === v.id &&
                        i.value.capabilityId === v.capabilityId &&
                        i.value.icon === v.icon &&
                        i.value.evaluate === v.evaluate &&
                        i.value.color === v.color) > -1
                ))
                _setItems(values.map(v => { return { id: uuid(), value: v } }) ?? []);
    }, [values])

    return (
        _options ?
            <div>
                {_items.map(item => (
                    <div key={item.id} className="d-flex mb-3">
                        <TelemetryPickerForMeshes id={item.value.id} active={item.id === _active} options={_options} activate={() => _setActive(item.id)} organizationId={organizationId} spaceId={item.value.spaceId} meterId={item.value.meterId} capabilityId={item.value.capabilityId} color={item.value.color} evaluate={item.value.evaluate} icon={item.value.icon} onChange={(value) => handleValue(item.id, value)}></TelemetryPickerForMeshes>
                        <a className="btn btn-custom btn-sm btn-icon btn-color-danger bg-body" onClick={() => handleRemove(item.id)}><i className="bi bi-trash"></i></a>
                    </div>
                ))}
                <a className="btn btn-custom btn-sm btn-icon btn-primary" onClick={handleAdd}><i className="bi bi-plus"></i></a>
            </div> :
            <div className="menu-item">
                <div className="menu-content text-center">
                    <span className="spinner-border text-primary"></span>
                </div>
            </div>
    )
}