import { useState, useEffect, useRef, useCallback, useMemo, ReactElement } from 'react';
import { apiService, Space } from '../../services/ApiService';
import uuid from 'react-uuid'
import './iconpicker.css'
import bootstrapIcons from 'bootstrap-icons/font/bootstrap-icons.json'
import Dropdown from 'react-bootstrap/Dropdown';

declare global {
    interface Window {
        KTComponents: { init: () => void };
    }
}

export interface IconPickerProps {
    className?: string;
    value?: string;
    onChange?: (value: string) => void;
};

function useOutsideAlerter(ref: React.RefObject<any>, hide: () => void) {
    useEffect(() => {
        const listener = (event: TouchEvent | MouseEvent) => {
            if (!ref || !ref.current)
                return;

            if (ref.current && !ref.current.contains(event.target))
                hide?.();
        }

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref]);
}

export const IconPicker: React.FC<IconPickerProps> = ({ className, value, onChange }) => {
    const [_icon, _setIcon] = useState<string>(value ?? 'bi bi-123')
    const [_show, _setShow] = useState<boolean>(false);

    const _ref = useRef<HTMLDivElement>(null);
    const _hide = () => _setShow(false);
    useOutsideAlerter(_ref, _hide);

    const _pageLenght = 35;
    const [_page, _setPage] = useState<number>(0);

    const [_search, _setSearch] = useState<string>('');
    const _items = useMemo(() => {
        return Object
            .keys(bootstrapIcons)
            .filter(i => i.includes(_search));
    }, [_search, _page])

    useEffect(() => {
        _hide(); onChange?.(_icon);
    }, [_icon]);
    useEffect(() => {
        window.KTComponents.init();
    }, []);

    return (
        <div ref={_ref} className="iconpicker dropdown">
            <button
                type="button"
                className={className ?? "btn btn-custom btn-sm btn-icon btn-color-primary bg-body"}
                onClick={() => _setShow(!_show)}
            >
                <i className={'fs-4 ' + _icon}></i>
            </button>
            <div className={_show ? 'dropdown-menu p-2 show' : 'dropdown-menu p-2'}>
                <div className="input-group mb-3">
                    <button
                        className="btn btn-custom btn-sm btn-icon btn-outline btn-color-primary bg-body"
                        type="button"
                        disabled={_page === 0}
                        onClick={() => { _setPage(_page - 1); }}
                    >
                        <i className="bi bi-caret-left-fill"></i>
                    </button>
                    <input type="text" className="form-control form-control-sm" placeholder="Search .." onChange={(e) => _setSearch(e.target.value) } aria-label="Example text with button addon" aria-describedby="button-addon1"></input>
                    <button
                        className="btn btn-custom btn-sm btn-icon btn-outline btn-color-primary bg-body"
                        type="button"
                        disabled={_page >= (_items.length / _pageLenght) -1}
                        onClick={() => { _setPage(_page + 1); } }
                    >
                        <i className="bi bi-caret-right-fill"></i>
                    </button>
                </div>
                <ul className="iconpicker">
                    {_items
                        .slice(_page * _pageLenght, (_page * _pageLenght) + _pageLenght)
                        .map(function (key) {
                        return (<li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={key}
                            onClick={() => _setIcon('bi bi-' + key) }
                        ><i className={'fs-4 text-primary bi bi-' + key}></i>
                        </li>)
                    })}
                </ul>
            </div>
        </div>
    )
}