import moment from 'moment'
import { useState, useEffect, useRef, useCallback } from 'react';

declare global {
    interface JQuery {
        daterangepicker: (
            options?: DateRangePickerOptions,
            format?: (start: moment.Moment, end: moment.Moment) => void) => void;
    }
}

type DateRangePickerOptions = {
    startDate?: moment.Moment,
    endDate?: moment.Moment,
    minDate?: moment.Moment,
    maxDate?: moment.Moment,
    ranges?: { [name: string]: moment.Moment[] }
}

type DateRangePickerProps = {
    className?: string;
    start?: Date;
    end?: Date;
    onChange?: (start: Date, end: Date) => void;
    min?: Date,
    max?: Date
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ className, start, end, onChange, min, max }) => {
    const [value, setvalue] = useState<string>(moment(start).format("MMMM D, YYYY") + " - " + moment(end).format("MMMM D, YYYY"))

    function change(start: moment.Moment, end: moment.Moment) {
        setvalue(start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"))
        onChange?.(start.toDate(), end.toDate());
    }

    const reference = useCallback((node: HTMLButtonElement) => {
        if(node)
            $(node).daterangepicker({
                startDate: moment(start),
                endDate: moment(end),
                minDate: min ? moment(min) : undefined,
                maxDate: max ? moment(max): undefined,
                ranges: {
                    "Today": [moment(), moment()],
                    "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                    "Last 7 Days": [moment().subtract(6, "days"), moment()],
                    "Last 30 Days": [moment().subtract(29, "days"), moment()],
                    "This Month": [moment().startOf("month"), moment().endOf("month")],
                    "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
                }
            } as DateRangePickerOptions, change);
    }, []);

    return (
        <button ref={reference} type="button" className={className ?? "btn"}>{value}</button>
    );
}