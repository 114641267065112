import { ApexOptions } from 'apexcharts';
import { useState, useEffect, useId, useMemo, useImperativeHandle, forwardRef, useContext } from "react";
import ApexChart from "react-apexcharts";
import uuid from 'react-uuid';
import { IWidget, Widget, WidgetTelemetry } from '../';
import { MultiTelemetryPicker, MultiTelemetryPickerWithColor } from '../../components/forms/telemetrypicker'
import { MultiLabelPicker, LabelPickerValue } from '../../components/forms/labelpicker'
import { Telemetry } from '../../services/ApiService'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ApplicationContext } from '../../services/ContextService'

export type ChartWidgetProps = {
	chartType?: "line"
	| "area"
	| "bar"
	| "histogram"
	| "pie"
	| "donut"
	| "radialBar"
	| "scatter"
	| "bubble"
	| "heatmap"
	| "treemap"
	| "boxPlot"
	| "candlestick"
	| "radar"
	| "polarArea"
	| "rangeBar";
	telemetry: WidgetTelemetry[];
	[settings: string]: unknown;
};
export type ChartUIProps = {
	organizationId: string;
	settings: any,
	telemetry: WidgetTelemetry[],
	onSettingsChange: (value: any) => void;
	onTelemetryChange: (value: WidgetTelemetry[]) => void;
};

const GetOptions = (chartId: string, type: string, configuration: any, widgetTelemetry: WidgetTelemetry[]): ApexOptions => {
	const _options = {
		chart: {
			height: '100%',
			id: chartId,
			toolbar: {
				show: false,
			},
			animations: {
				enabled: true,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350
				}
			}
		}
	} as ApexOptions;

	switch (type) {
		case 'donut':
		case 'pie':
			_options.labels = configuration?.settings?.labels?.map((l: { name: any; }) => l.name);
			_options.colors = configuration?.settings?.labels?.map((l: { color: any; }) => l.color);
			if (configuration?.settings?.showOthers === 'yes') {
				_options.labels?.push('Other');
				_options.colors?.push('#c9c9c9');
			}
			break;
		case 'radialBar':
			_options.plotOptions = {
				radialBar: {
					dataLabels: {
						total: {
							show: true,
							formatter: function (val) {
								const sum = val.config.series?.reduce((a: number, b: number) => a + b, 0) ?? 0;
								const avg = (sum / val.config.series?.length) || 0;
								
								return Math.round(avg) + '%'
							}
						},
						value: {
							show: true,
							fontSize: '14px',
							formatter: function (val) {
								return Math.round(val) + '%'
							}
						},
					},
					startAngle: 0,
					endAngle: 360,
					track: {
						startAngle: 0,
						endAngle: 360,
					}
				}
			};
			_options.labels = widgetTelemetry?.map(w => w.name);
			_options.colors = widgetTelemetry?.map(w => w.color);
			break;
		case 'bar':
			_options.plotOptions = {
				bar: {
					horizontal: configuration?.settings?.horizontal === 'yes'
				}
			};
			_options.dataLabels = {
				enabled: false
			};
			if (_options.chart) // witch is always true ...
			{
				_options.chart.stacked = configuration?.settings?.stacked === 'yes';
				_options.chart.stackType = configuration?.settings?.stacked === 'yes' ? "100%" : "normal";
			}
			_options.xaxis = {
				type: 'datetime',
				labels: {
					datetimeFormatter: {
						year: 'yyyy',
						month: 'MMM \'yy',
						day: 'dd MMM',
						hour: 'HH:mm'
					}
				}
			};
			_options.yaxis = {
				forceNiceScale: true,
				decimalsInFloat: 2
			};
			if (configuration?.settings?.horizontal === 'yes') {
				_options.yaxis.labels = {
					formatter: (val: number, opts?: any) => {
						var date = new Date(val);
						return date.toString();
					}
				}
			}
			break;
		default:
			_options.xaxis = {
				type: 'datetime',
				labels: {
					datetimeFormatter: {
						year: 'yyyy',
						month: 'MMM \'yy',
						day: 'dd MMM',
						hour: 'HH:mm'
					},
					datetimeUTC: false
				}
			};
			_options.dataLabels = {
				enabled: false
			};
			_options.yaxis = {
				forceNiceScale: true,
				decimalsInFloat: 2
			};
			break;
	}

	return _options;
}
const GetSeries = (type: string, configuration: any, widgetTelemetry: WidgetTelemetry[], data: Telemetry[]): ApexOptions['series'] => {
	switch (type) {
		case 'donut':
		case 'pie':
			const _dbShowOthers = configuration?.settings?.showOthers === 'yes';
			const _dbLabels = configuration?.settings?.labels?.map((l: { name: any; }) => l.name) as Array<any>;
			if (_dbShowOthers)
				_dbLabels.push('');
			const _dpValues = new Array(_dbLabels.length).fill(0);
			data.forEach(d => {
				d.data.forEach(t => {
					if (_dbLabels.indexOf(t.value) > -1)
						_dpValues[_dbLabels.indexOf(t.value)]++
					else if (_dbShowOthers)
						_dpValues[_dpValues.length - 1]++
				});
			});
			return _dpValues as ApexOptions['series'];
		case 'radialBar':
			const _rbValues = widgetTelemetry?.map(w => { return { id: w.id, value: 0 } });
			const _rbCalc = (value: number) => {
				const _rbMin = configuration?.settings?.minValue ?? 0;
				const _rbMax = configuration?.settings?.maxValue ?? 0;
				const _rbDiff = _rbMax - _rbMin;
				const _rbCorrect = _rbMin < 0 ? Math.abs(_rbMin) : 0;

				if(_rbMin < 0)

				if (value < _rbMin)
					value = _rbMin;
				if (value > _rbMax)
					value = _rbMax;

				value += _rbCorrect;

				return (value /_rbDiff) * 100
			};
			data.forEach(d => {
				const _index = _rbValues.findIndex(v => v.id === d.id);
				if (_index <= -1)
					return;
				else
					_rbValues[_index].value = _rbCalc(d.data[d.data.length -1].value as number)

			});
			return _rbValues?.map(w => w.value) as ApexOptions['series']
		case 'bar':
			return data.map(t => {
				return {
					name: widgetTelemetry.find(i => i.id === t.id)?.name,
					color: widgetTelemetry.find(i => i.id === t.id)?.color,
					data: t.data.map(d => { return { x: d.date, y: d.value }; })
				}
			}) as ApexOptions['series']
		default:
			return data.map(t => {
				return {
					name: widgetTelemetry.find(i => i.id === t.id)?.name,
					color: widgetTelemetry.find(i => i.id === t.id)?.color,
					data: t.data.map(d => { return { x: d.date, y: d.value }; })
				}
			}) as ApexOptions['series']
	}
}

const DonutPieChartUI: React.FC<ChartUIProps> = ({ organizationId, settings, telemetry, onSettingsChange, onTelemetryChange }) => {
	const [labels, setLabels] = useState<LabelPickerValue[]>(settings?.labels as LabelPickerValue[] ?? []);
	const [others, setOthers] = useState<'yes' | 'no'>(settings?.showOthers as 'yes'|'no' ?? 'yes');

	useEffect(() => onSettingsChange({ labels: labels, showOthers: others }), [labels, others]);

	return (
		<>
			<div className="mb-7">
				<label className="required fs-6 fw-semibold form-label mb-2">Labels</label>
				<MultiLabelPicker values={labels} onChange={(values) => setLabels(values)} ></MultiLabelPicker>
			</div>
			<div className="mb-7 form-check form-switch form-check-custom form-check-solid">
				<input className="form-check-input" type="checkbox" checked={others === 'yes'} onChange={(event) => setOthers(event.target.checked ? 'yes' : 'no')} />
				<label className="form-check-label" htmlFor="dashboard_name">
					Show unmatched items as "Other"
				</label>
			</div>
			<div className="mb-7">
				<label className="required fs-6 fw-semibold form-label mb-2">Telemetry</label>
				<MultiTelemetryPicker organizationId={organizationId} values={telemetry.map(t => { return { organizationId: organizationId, capabilityId: t.id, name: t.name } })} onChange={(values) => onTelemetryChange(values.map(v => { return { id: v.capabilityId as string, name: v.name as string } }))}></MultiTelemetryPicker>
			</div>
		</>
	)
}
const RadialBarChartUI: React.FC<ChartUIProps> = ({ organizationId, settings, telemetry, onSettingsChange, onTelemetryChange }) => {
	const [minValue, setMinValue] = useState<number>(settings?.minValue as number ?? 0);
	const [maxValue, setMaxValue] = useState<number>(settings?.maxValue as number ?? 100);

	useEffect(() => onSettingsChange({ minValue: minValue, maxValue: maxValue }), [minValue, maxValue]);

	return (
		<>
			<div className="mb-7">
				<label className="required fs-6 fw-semibold form-label mb-2">Minimal Value</label>
				<input className="form-control form-control-sm" type="number" value={minValue} onChange={((event) => setMinValue(Number(event.target.value)))} ></input>
			</div>
			<div className="mb-7">
				<label className="required fs-6 fw-semibold form-label mb-2">Maximum Value</label>
				<input className="form-control form-control-sm" type="number" value={maxValue} onChange={((event) => setMaxValue(Number(event.target.value)))} ></input>
			</div>
			<div className="mb-7">
				<label className="required fs-6 fw-semibold form-label mb-2">Telemetry</label>
				<MultiTelemetryPickerWithColor organizationId={organizationId} values={telemetry.map(t => { return { organizationId: organizationId, capabilityId: t.id, name: t.name, color: t.color } })} onChange={(values) => onTelemetryChange(values.map(v => { return { id: v.capabilityId as string, name: v.name as string, color: v.color as string } }))}></MultiTelemetryPickerWithColor>
			</div>
		</>
	)
}
const BarColumnChartUI: React.FC<ChartUIProps> = ({ organizationId, settings, telemetry, onSettingsChange, onTelemetryChange }) => {
	const [horizontal, setHorizontal] = useState<'yes' | 'no'>(settings?.horizontal as 'yes' | 'no' ?? 'no');
	const [stacked, setStacked] = useState<'yes' | 'no'>(settings?.stacked as 'yes' | 'no' ?? 'no');

	useEffect(() => onSettingsChange({ horizontal: horizontal, stacked: stacked }), [horizontal, stacked]);

	return (
		<>
			<div className="mb-7 form-check form-switch form-check-custom form-check-solid">
				<input className="form-check-input" type="checkbox" checked={horizontal === 'yes'} onChange={(event) => setHorizontal(event.target.checked ? 'yes' : 'no')} />
				<label className="form-check-label">
					Horizontal
				</label>
			</div>
			<div className="mb-7 form-check form-switch form-check-custom form-check-solid">
				<input className="form-check-input" type="checkbox" checked={stacked === 'yes'} onChange={(event) => setStacked(event.target.checked ? 'yes' : 'no')} />
				<label className="form-check-label">
					Stacked
				</label>
			</div>
			<div className="mb-7">
				<label className="required fs-6 fw-semibold form-label mb-2">Telemetry</label>
				<MultiTelemetryPickerWithColor organizationId={organizationId} values={telemetry.map(t => { return { organizationId: organizationId, capabilityId: t.id, name: t.name, color: t.color } })} onChange={(values) => onTelemetryChange(values.map(v => { return { id: v.capabilityId as string, name: v.name as string, color: v.color as string } }))}></MultiTelemetryPickerWithColor>
			</div>

		</>
	)
}
const DefaultChartUI: React.FC<ChartUIProps> = ({ organizationId, telemetry, onTelemetryChange }) => {
	return (
		<div className="mb-7">
			<label className="required fs-6 fw-semibold form-label mb-2">Telemetry</label>
			<MultiTelemetryPickerWithColor organizationId={organizationId} values={telemetry.map(t => { return { organizationId: organizationId, capabilityId: t.id, name: t.name, color: t.color } })} onChange={(values) => onTelemetryChange(values.map(v => { return { id: v.capabilityId as string, name: v.name as string, color: v.color as string } }))}></MultiTelemetryPickerWithColor>
		</div>
	)
}
const componentOf: { [name: string]: React.FC<ChartUIProps> } = {
	'pie': DonutPieChartUI,
	'donut': DonutPieChartUI,
	'radialBar': RadialBarChartUI,
	'bar': BarColumnChartUI
};

export const ChartWidget: IWidget<ChartWidgetProps> = {
	Configure: ({ widget, onSave, onCancel }) => {
		const ctx = useContext(ApplicationContext);
		const [show, setShow] = useState(false);
		useEffect(() => setShow(true), []);

		const { id, title, type, position, chartType, telemetry, ...rest } = widget as Widget;

		const [_title, _setTitle] = useState<string>(title ?? '');
		const [_type, _setType] = useState<string>(chartType as string ?? 'line');
		const [_telemetry, _setTelemetry] = useState<WidgetTelemetry[]>(telemetry ?? []);
		const [_settings, _setSettings] = useState<any>(rest.settings ?? {});


		const handleClose = () => {
			onCancel?.();
			setShow(false);
		}
		const handleSave = () => {
			const _widget: Widget = {
				id: id,
				title: _title,
				type: 'chart',
				position: position ?? {
					w: 6,
					h: 6,
					x: 0,
					y: 0,
					minW: 4,
					minH: 4
				},
				chartType: _type,
				telemetry: _telemetry,
				historical: true,
				settings: _settings
			}

			onSave?.(_widget);
			setShow(false);
		}

		const UI = useMemo(() => componentOf[_type] ?? DefaultChartUI, [_type]);
		return (
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Chart</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-7">
							<Form.Label className="required fs-6 fw-semibold form-label mb-2">Title</Form.Label>
							<Form.Control
								className="form-control-solid"
								size="sm"
								type="text"
								placeholder="Enter title .."
								value={_title}
								onChange={(e) => { _setTitle(e.target.value) }}
								autoFocus
							/>
						</Form.Group>
						<Form.Group className="mb-7">
							<Form.Label className="required fs-6 fw-semibold form-label mb-2">Type</Form.Label>
							<Form.Select
								className="form-control-solid"
								size="sm"
								value={_type}
								onChange={(e) => { _setType(e.target.value) }}
							>
								<option value="line">Line</option>
								<option value="area">Area</option>
								<option value="bar">Bar</option>
								<option value="pie">Pie</option>
								<option value="donut">Donut</option>
								<option value="radialBar">RadialBar</option>
								<option value="scatter">Scatter</option>
								<option value="heatmap">Heatmap</option>
								<option value="treemap">Treemap</option>
								<option value="radar">Radar</option>
							</Form.Select>
						</Form.Group>
						<UI
							organizationId={ctx.organizationId as string}
							settings={_settings}
							onSettingsChange={(value) => _setSettings(value)}
							telemetry={_telemetry}
							onTelemetryChange={(value) => _setTelemetry(value)} />
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" size="sm" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" size="sm" onClick={handleSave}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		)
	},
	Render: forwardRef((props, ref) => {
		const { chartType, telemetry, ...settings } = props as ChartWidgetProps;
		const chartId = useId();
		const chartOptions = useMemo(() => GetOptions(chartId, chartType as string, settings, telemetry), [chartId, chartType, settings]);

		const [series, setSeries] = useState<ApexOptions['series']>([]);
		const [interest, setInterest] = useState<string[]>(telemetry.map(t => t.id));

		useImperativeHandle(ref, () => ({
			onData(data: Telemetry[]) {
				let _interest = data.filter(t => interest.indexOf(t.id) > -1);
				let _series = GetSeries(chartType as string, settings, telemetry, _interest);
				setSeries(_series as ApexOptions['series']);
			}
		}));

		useEffect(() => {
			setInterest(telemetry.map(t => t.id));
		}, [telemetry])

		return (
			<>
				<div id="kt_charts_widget_3" onResize={(event) => alert(event)} className="h-100 ps-4 pe-6 pb-4">
					<ApexChart
						key={chartId + chartType}
						options={chartOptions}
						type={chartType}
						series={series}
						width="100%"
						height="100%"
					/>
				</div >
			</>
		)
	})
}