import { Component, useState } from 'react';
import { authService } from '../../services/AuthService';
import { apiService, Organization } from '../../services/ApiService';
import { ProfilePhoto } from './ProfilePhoto'
import { ProfileMenuSeperator } from './ProfileMenuSeperator'
import { ProfileMenuItem } from './ProfileMenuItem';


export type ProfileSubmenuProps = {
	title: string;
	children?: React.ReactNode | React.ReactNode[];
};

export const ProfileSubmenu: React.FC<ProfileSubmenuProps> = ({ title, children }) => {
	return (
		<div className="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-end" data-kt-menu-offset="0, 0">
			<a href="#" className="menu-link px-5">
				<span className="menu-title">{title}</span>
				<span className="menu-arrow"></span>
			</a>
			<div className="menu-sub menu-sub-dropdown w-175px py-4">
				{children}
			</div>
		</div>
	);
}